<template>
    <section>
        <!-- 选择栏 -->
        <el-col :span="24" class="toolbar" style="padding-bottom: 0; padding-left: 5px;">
            <el-form :inline="true" :model="filters">
                <el-form-item label="名称:">
                    <el-input size="small" v-model="filters.name" clearable></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" size="small" v-on:click="handleList()">查询</el-button>
                    <el-button size="small" v-on:click="handleAdd()">添加</el-button>
                </el-form-item>
            </el-form>
        </el-col>

        <!-- 列表 -->
        <template>
            <el-table :data="dataList" stripe highlight-current-row v-loading="dataLoading" size="small" height="800"
                style="width: 100%;">
                <el-table-column prop="id" label="id" width="100"></el-table-column>
                <el-table-column prop="name" label="名称" width="100"></el-table-column>
                <el-table-column prop="rebate" label="默认返点" width="100"></el-table-column>
                <el-table-column prop="rebate_guest" label="客户返点" width="100"></el-table-column>
                <el-table-column prop="bind_guest" label="绑定客户" width="150" :show-overflow-tooltip="true"></el-table-column>
                <el-table-column prop="create_time" label="创建时间" width="150" :formatter="formatTime"></el-table-column>
                <el-table-column label="操作" min-width="200" fixed="right">
                    <template slot-scope="scope">
                        <el-button size="small" @click="handleEdit(scope.row)">修改</el-button>
                        <el-button type="danger" size="small" @click="handleDelete(scope.row)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <!-- 翻页 -->
            <el-col :span="24" class="toolbar">
                <el-pagination layout="total, sizes, prev, pager, next" :current-page="this.page"
                    :total="total"
                    :page-size="pageSize" 
                    @current-change="handleCurrentChange" 
                    @size-change="handleSizeChange"
                    :page-sizes="[100,200,400]"
                    style="float:right;">
                </el-pagination>
            </el-col> 
        </template>

        <!-- 添加/修改 -->
        <el-dialog title="添加/修改" :visible.sync="formVisible" :close-on-click-modal="false" width="60%">
            <el-form :model="formData" label-width="100px" ref="form" label-position="left">
                <el-row v-if="formType == 2">
                    <el-col :span="6">
                        <el-form-item label="id" prop="id" required >
                            <el-input size="small" v-model="formData.id" disabled="disabled"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="6">
                        <el-form-item label="名称" prop="name" required >
                            <el-input size="small" v-model="formData.name"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="6">
                        <el-form-item label="默认返点" prop="rebate" required >
                            <el-input size="small" v-model.number="formData.rebate"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="6">
                        <el-form-item label="客户端返点" prop="rebate_guest" required >
                            <el-input size="small" v-model.number="formData.rebate_guest"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="绑定客户">
                            <el-select size="small" v-model="formData.bind_guest" value="" multiple filterable style="width:80%">
                                <el-option v-for="item in guestAccountOptions" :key="item.value" :value="item.value" :label="item.label"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click.native="formCancel('form')">取消</el-button>
                <el-button type="primary" @click.native="handleSubmit" :loading="formLoading">提交</el-button>
            </div>
        </el-dialog>
    </section>
</template>

<script>
import util from "../../common/js/util";
import model from "../../common/js/model";
import { postRequest, formatTimestamp} from "../../api/api";

export default {
    data() {
        return {
            filters: {
                name: "",
            },

            page: 1,
            pageSize: 100,
            total: 1,
            dataList: [],
            dataLoading: false,
            guestAccountOptions: [],

            formType : 0,
            formVisible: false,
            formLoading: false,

            formData: {
                id: 0,
                name: "",
                rebate: 0,
                rebate_guest: 0,
                bind_guest: [],
                create_time: 0,
            },
        }
    },

    methods: {
        formatTime(row, column, cellValue) {
            if (cellValue == 0) {
                return "0000-00-00 00:00:00"
            }
            return formatTimestamp(cellValue);
        },

        handleList() {
            let params = {
                page: this.page,
                pageSize: this.pageSize,
                name: this.filters.name,
            }
            this.dataLoading = true;
            let queryModel = model.GetQueryModel("PlatformCtrl", "GetList", params);
            postRequest(queryModel).then(res => {
                this.dataLoading = false;

                if (res.data.msg !== "") {
                    this.$message.warning(res.data.msg);
                    return;
                }
                if (this.page === 1) {
                    this.total = res.data.total;
                }
                this.dataList = res.data.datas;
                console.log("handleList", this.dataList);

            });
        },

        handleSubmit() {
            this.$refs.form.validate(valid => {
                if (valid) {
                    this.$confirm("确认提交吗？", "提示", {}).then(() => {
                        this.formLoading = true;
                        let params = Object.assign({}, this.formData);
                        params.bind_guest = JSON.stringify(params.bind_guest);
                        var queryModel = model.GetQueryModel("PlatformCtrl", "Add", params);
                        postRequest(queryModel).then(res => {
                            this.formLoading = false;
                            if (!util.isEmpty(res.data.msg)) {
                                this.$message.warning(res.data.msg);
                                return;
                            } 
                            this.$message.success("操作成功");
                            this.formVisible = false;
                            this.$refs.form.resetFields();
                            this.handleList();
                        });
                    });
                }
            });
        },

        handleAdd() {
            this.formType = 1;
            this.formVisible = false;
            this.formLoading = false;
            this.formData = {
                id: 0,
                name: "",
                rebate: 0,
                rebate_guest: 0,
                bind_guest: [],
                create_time: 0,
            },
            this.formVisible = true;
        },

        handleEdit(row) {
            this.formType = 2;
            this.formVisible = false;
            this.formLoading = false;
            this.formData = Object.assign({}, row);
            this.formData.bind_guest = JSON.parse(row.bind_guest);
            this.formVisible = true;
        },


        handleDelete: function (row) {
            this.$confirm("确认删除吗?", "提示", {type: "warning"}).then(() => {
                let params = {
                    id: row.id,
                };
                var queryModel = model.GetQueryModel("PlatformCtrl", "Delete", params);
                postRequest(queryModel).then(res => {
                    if (!util.isEmpty(res.data.msg)) {
                        this.$message({ message: res.data.msg, type: "warning" });
                        return;
                    } 
                    this.$message.success("删除成功");
                    this.handleList();
                });
            });
        },

        formCancel(ref) {
            if (ref === "form") {
                this.formVisible = false;
            }
            this.$refs[ref].resetFields();
        },

        handleCurrentChange(page) {
            this.page = page;
            this.handleList();
        },

        handleSizeChange(val) {
            this.pageSize = val;
            this.handleList();
        },

        handleGuestAccountList() {
            let params = {};
            let queryModel = model.GetQueryModel("AccountMgrCtrl", "GetGuestAccountList", params);
            postRequest(queryModel).then(res => {
                if (res.data.msg !== "") {
                    this.$message.warning(res.data.msg);
                    return;
                }
                for (let val of res.data.datas) {
                    this.guestAccountOptions.push({label:val.name, value:val.id});
                }
                console.log("handleGuestAccountList", this.guestAccountOptions);
            });
        },
    },
    mounted() {
        this.handleList();
        this.handleGuestAccountList();
    }
}
</script>
<style scoped>
</style>