var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      [
        _c(
          "el-table",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.dataLoading,
                expression: "dataLoading",
              },
            ],
            staticStyle: { width: "100%" },
            attrs: {
              data: _vm.dataList,
              stripe: "",
              "highlight-current-row": "",
              size: "small",
              height: "800",
            },
          },
          [
            _c("el-table-column", {
              attrs: {
                prop: "platform",
                label: "平台",
                width: "100",
                formatter: _vm.formatPlatform,
              },
            }),
            _vm._v(">\n            "),
            _c("el-table-column", {
              attrs: { prop: "uc_id", label: "账号id", width: "150" },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: { prop: "uc_name", label: "账号名", width: "150" },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: { prop: "master_uid", label: "管家id", width: "150" },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: { prop: "master_name", label: "管家名", width: "150" },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                prop: "create_time",
                label: "创建时间",
                width: "150",
                formatter: _vm.formatTime,
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "el-col",
          { staticClass: "toolbar", attrs: { span: 24 } },
          [
            _c("el-pagination", {
              staticStyle: { float: "right" },
              attrs: {
                layout: "total, sizes, prev, pager, next",
                "current-page": this.page,
                total: _vm.total,
                "page-size": _vm.pageSize,
                "page-sizes": [100, 200, 400],
              },
              on: {
                "current-change": _vm.handleCurrentChange,
                "size-change": _vm.handleSizeChange,
              },
            }),
          ],
          1
        ),
      ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }