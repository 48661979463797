<template>
    <el-input :placeholder="placeholder" v-model="fileName" @keyup.enter.native="emitDownloadEvent" size="mini">
        <el-button slot="append" @click="emitDownloadEvent">
            <slot>{{operationName}}</slot>
        </el-button>
    </el-input>
</template>
<script>
export default {
    props: {
        placeholder: {
            type: String,
            default: '允许修改导出的文件名'
        },
        operationName: {
            type: String,
            default: '导出'
        },

    },
    data() {
        return {
            fileName: '',
        }
    },
    methods: {
        emitDownloadEvent() {
            this.$emit('download', {
                fileName: this.fileName
            })
        }
    }
}
</script>
<style>
.el-form-item .el-form-item__content .el-input-group {
    vertical-align: middle;
}
</style>

