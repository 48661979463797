var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c(
        "el-col",
        {
          staticClass: "toolbar",
          staticStyle: { "padding-bottom": "0", "padding-left": "5px" },
          attrs: { span: 24 },
        },
        [
          _c(
            "el-form",
            { attrs: { inline: true, model: _vm.filters, size: "small" } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "推广账号", prop: "advAccountName" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "140px" },
                      attrs: {
                        size: "small",
                        value: "",
                        filterable: "",
                        clearable: "",
                      },
                      model: {
                        value: _vm.filters.advAccountName,
                        callback: function ($$v) {
                          _vm.$set(_vm.filters, "advAccountName", $$v)
                        },
                        expression: "filters.advAccountName",
                      },
                    },
                    _vm._l(_vm.bindAdvAccountOptions, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { value: item.value, label: item.label },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              !_vm.gconfig.isGuest()
                ? _c(
                    "el-form-item",
                    { attrs: { label: "客户账号", prop: "guestAccountId" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "140px" },
                          attrs: {
                            size: "small",
                            value: "",
                            filterable: "",
                            clearable: "",
                          },
                          model: {
                            value: _vm.filters.guestAccountId,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.filters,
                                "guestAccountId",
                                _vm._n($$v)
                              )
                            },
                            expression: "filters.guestAccountId",
                          },
                        },
                        _vm._l(_vm.guestAccountOptions, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { value: item.value, label: item.label },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.gconfig.isAdmin()
                ? _c(
                    "el-form-item",
                    { attrs: { label: "运营账号", prop: "staffAccountId" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "140px" },
                          attrs: {
                            size: "small",
                            value: "",
                            filterable: "",
                            clearable: "",
                          },
                          model: {
                            value: _vm.filters.staffAccountId,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.filters,
                                "staffAccountId",
                                _vm._n($$v)
                              )
                            },
                            expression: "filters.staffAccountId",
                          },
                        },
                        _vm._l(_vm.staffAccountOptions, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { value: item.value, label: item.label },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "平台", prop: "platformId" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "140px" },
                      attrs: {
                        size: "small",
                        value: "",
                        filterable: "",
                        clearable: "",
                      },
                      model: {
                        value: _vm.filters.platformId,
                        callback: function ($$v) {
                          _vm.$set(_vm.filters, "platformId", $$v)
                        },
                        expression: "filters.platformId",
                      },
                    },
                    _vm._l(_vm.platformOptions, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { value: item.value, label: item.label },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "域名", prop: "domainName" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "140px" },
                    attrs: { size: "small", clearable: "" },
                    model: {
                      value: _vm.filters.domainName,
                      callback: function ($$v) {
                        _vm.$set(_vm.filters, "domainName", $$v)
                      },
                      expression: "filters.domainName",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "日期" } },
                [
                  _c("el-date-picker", {
                    staticStyle: { width: "250px" },
                    attrs: {
                      size: "small",
                      type: "daterange",
                      "value-format": "timestamp",
                      "range-separator": "至",
                      "start-placeholder": "开始日期",
                      "end-placeholder": "结束日期",
                    },
                    model: {
                      value: _vm.filters.timeRange,
                      callback: function ($$v) {
                        _vm.$set(_vm.filters, "timeRange", $$v)
                      },
                      expression: "filters.timeRange",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      staticStyle: { width: "50px" },
                      attrs: { type: "primary", size: "small" },
                      on: {
                        click: function ($event) {
                          return _vm.handleList()
                        },
                      },
                    },
                    [_vm._v("查询")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c("export-file", {
                    staticStyle: { width: "150px" },
                    on: { download: _vm.handleExport },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      [
        _c(
          "el-table",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.dataLoading,
                expression: "dataLoading",
              },
            ],
            staticStyle: { width: "100%" },
            attrs: {
              data: _vm.dataList,
              stripe: "",
              "highlight-current-row": "",
              size: "small",
              height: "800",
            },
          },
          [
            _c("el-table-column", {
              attrs: {
                prop: "adv_account",
                label: "推广账号",
                sortable: "",
                width: "150",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      scope.row.adv_account == ""
                        ? _c("span", [_vm._v("未绑定")])
                        : _c(
                            "span",
                            {
                              staticStyle: {
                                color: "green",
                                "font-weight": "bold",
                              },
                            },
                            [_vm._v(_vm._s(scope.row.adv_account) + " ")]
                          ),
                    ]
                  },
                },
              ]),
            }),
            _vm._v(" "),
            !_vm.gconfig.isGuest()
              ? _c("el-table-column", {
                  attrs: {
                    prop: "guest_account",
                    label: "客户账号",
                    sortable: "",
                    width: "100",
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.guest_account == ""
                              ? _c("span", [_vm._v("未绑定")])
                              : _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      color: "green",
                                      "font-weight": "bold",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(scope.row.guest_account) + " "
                                    ),
                                  ]
                                ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    4174550931
                  ),
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.gconfig.isAdmin()
              ? _c("el-table-column", {
                  attrs: {
                    prop: "staff_account",
                    label: "运营账号",
                    sortable: "",
                    width: "100",
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.staff_account == ""
                              ? _c("span", [_vm._v("未绑定")])
                              : _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      color: "orange",
                                      "font-weight": "bold",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(scope.row.staff_account) +
                                        " "
                                    ),
                                  ]
                                ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    109630771
                  ),
                })
              : _vm._e(),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                prop: "adv_cost_total",
                label: "消耗金额",
                width: "100",
                sortable: "",
                "sort-method": function (a, b) {
                  return a.adv_cost_total - b.adv_cost_total
                },
              },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                prop: "income_line",
                label: "进线数量",
                width: "100",
                sortable: "",
                "sort-method": function (a, b) {
                  return a.income_line - b.income_line
                },
              },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                prop: "income_price",
                label: "进线成本",
                width: "100",
                sortable: "",
                "sort-method": function (a, b) {
                  return a.income_price - b.income_price
                },
              },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                prop: "cash_cost",
                label: "现金消耗",
                width: "100",
                sortable: "",
                "sort-method": function (a, b) {
                  return a.cash_cost - b.cash_cost
                },
              },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                prop: "cash_price",
                label: "现金成本",
                width: "100",
                sortable: "",
                "sort-method": function (a, b) {
                  return a.cash_price - b.cash_price
                },
              },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                prop: "deal_num",
                label: "成交数量",
                width: "100",
                sortable: "",
                "sort-method": function (a, b) {
                  return a.deal_num - b.deal_num
                },
              },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                prop: "deal_rate",
                label: "成交率",
                width: "100",
                sortable: "",
                "sort-method": function (a, b) {
                  return a.deal_rate - b.deal_rate
                },
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _vm._v(
                        "\n                    " +
                          _vm._s(scope.row.deal_rate) +
                          "%\n                "
                      ),
                    ]
                  },
                },
              ]),
            }),
            _vm._v(" "),
            !_vm.gconfig.isGuest()
              ? _c("el-table-column", {
                  attrs: {
                    prop: "check_std",
                    label: "考核标准",
                    width: "100",
                    sortable: "",
                    "sort-method": function (a, b) {
                      return a.check_std - b.check_std
                    },
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            !_vm.gconfig.isGuest()
              ? _c("el-table-column", {
                  attrs: {
                    prop: "invalid_num",
                    label: "无效资料数量",
                    width: "120",
                    sortable: "",
                    "sort-method": function (a, b) {
                      return a.invalid_num - b.invalid_num
                    },
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            !_vm.gconfig.isGuest()
              ? _c("el-table-column", {
                  attrs: {
                    prop: "invalid_rate",
                    label: "无效资料占比",
                    width: "120",
                    sortable: "",
                    "sort-method": function (a, b) {
                      return a.invalid_rate - b.invalid_rate
                    },
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              "\n                    " +
                                _vm._s(scope.row.invalid_rate) +
                                "%\n                "
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    803762228
                  ),
                })
              : _vm._e(),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                prop: "adv_balance_total",
                label: "账号余额",
                width: "150",
                sortable: "",
                "sort-method": function (a, b) {
                  return a.adv_balance_total - b.adv_balance_total
                },
              },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                prop: "platform",
                label: "平台",
                width: "100",
                formatter: _vm.formatPlatform,
              },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: { prop: "domain", label: "域名", width: "150" },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: { label: "操作", "min-width": "240", fixed: "right" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _vm.gconfig.isAdmin() &&
                      scope.row["adv_account"] != "合计"
                        ? _c(
                            "el-button",
                            {
                              style:
                                "width: 50px; margin-left:3px;font-weight:bold;" +
                                (scope.row.hide == 0
                                  ? "color:green"
                                  : "color:gray"),
                              attrs: { size: "small" },
                              on: {
                                click: function ($event) {
                                  return _vm.handleHide(scope.row)
                                },
                              },
                            },
                            [_vm._v("隐藏")]
                          )
                        : _vm._e(),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "el-col",
          { staticClass: "toolbar", attrs: { span: 24 } },
          [
            _c("el-pagination", {
              staticStyle: { float: "right" },
              attrs: {
                layout: "total, sizes, prev, pager, next",
                "current-page": this.page,
                total: _vm.total,
                "page-size": _vm.pageSize,
                "page-sizes": [100, 200, 400, 800],
              },
              on: {
                "current-change": _vm.handleCurrentChange,
                "size-change": _vm.handleSizeChange,
              },
            }),
          ],
          1
        ),
      ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }