<template>
    <section>
        <!-- 选择栏 -->
        <el-col :span="24" class="toolbar" style="padding-bottom: 0; padding-left: 5px;">
            <el-form :inline="true" :model="filters">
                <el-form-item label="账号名:">
                    <el-input size="small" v-model="filters.accountName" clearable></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" size="small" v-on:click="handleAdminList()">查询</el-button>
                    <el-button size="small" v-on:click="handleAdd()">添加</el-button>
                </el-form-item>
            </el-form>
        </el-col>

        <!-- 列表 -->
        <template>
            <el-table :data="dataList" stripe highlight-current-row v-loading="dataLoading" size="small" height="800"
                style="width: 100%;">
                <el-table-column prop="id" label="用户id" width="100"></el-table-column>
                <el-table-column prop="name" label="用户名" width="150"></el-table-column>
                <el-table-column prop="login_ip" label="登陆ip" width="120"></el-table-column>
                <el-table-column prop="login_time" label="登陆时间" width="150" :formatter="formatTime"></el-table-column>
                <el-table-column prop="create_time" label="创建时间" width="150" :formatter="formatTime"></el-table-column>
                <el-table-column prop="account_type" label="账号类型" width="80" >
                    <template slot-scope="scope">
                        <span v-if="scope.row.account_type==accountType.guest" style="color:green;"> 客户 </span>
                        <span v-if="scope.row.account_type==accountType.admin" style="color:red;"> 管理 </span>
                        <span v-if="scope.row.account_type==accountType.staff" style="color:orange;"> 运营 </span>
                    </template>
                </el-table-column>
                <el-table-column prop="privileges" label="可见权限" width="200" :formatter="formatPrivileges" :show-overflow-tooltip="true"></el-table-column>
                <el-table-column prop="domains" label="绑定域名" width="200" :formatter="formatDomains" :show-overflow-tooltip="true"></el-table-column>
                <el-table-column label="操作" min-width="200" fixed="right">
                    <template slot-scope="scope">
                        <el-button size="small" @click="handleEdit(scope.row)">修改</el-button>
                        <el-button type="danger" size="small" @click="handleDelete(scope.row)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <!-- 翻页 -->
            <el-col :span="24" class="toolbar">
                <el-pagination layout="total, sizes, prev, pager, next" :current-page="this.page"
                    :total="total"
                    :page-size="pageSize" 
                    @current-change="handleCurrentChange" 
                    @size-change="handleSizeChange"
                    :page-sizes="[100,200,400]"
                    style="float:right;">
                </el-pagination>
            </el-col> 
        </template>

        <!-- 添加/修改 -->
        <el-dialog title="修改/添加" :visible.sync="formVisible" :close-on-click-modal="false" width="60%">
            <el-form :model="formData" label-width="100px" ref="form" label-position="left">
                <el-row v-if="formType==2">
                    <el-col :span="12">
                        <el-form-item label="账号id" prop="id" required >
                            <el-input size="small" v-model="formData.id" :disabled="true"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="账号名" prop="name" required>
                            <el-input size="small" v-model="formData.name"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="密码" prop="passwd" required>
                            <el-input size="small" v-model="formData.passwd" :disabled="true"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="4" style="margin-left:10px;">
                        <el-button size="small" @click="handleSetPasswd()">设置密码</el-button>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="账号类型" required>
                            <el-select v-model="formData.account_type" value="" style="width:40%">
                                <el-option v-for="item in accountTypeOptions" :key="item.value" :value="item.value" :label="item.label"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="可见权限">
                            <el-select size="small" v-model="formData.privileges" value="" multiple filterable style="width:80%">
                                <el-option v-for="item in privilegeOptions" :key="item.value" :value="item.value" :label="item.label"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row v-if="formData.account_type!=accountType.admin">
                    <el-col :span="24">
                        <el-form-item label="绑定域名">
                            <el-select size="small" v-model="formData.domains" value="" filterable multiple style="width:80%">
                                <el-option v-for="item in domainOptions" :key="item.value" :value="item.value" :label="item.label"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click.native="formCancel('form')">取消</el-button>
                <el-button type="primary" @click.native="formSubmit" :loading="formLoading">提交</el-button>
            </div>
        </el-dialog>
        <!-- 设置密码 -->
        <el-dialog title="设置密码" :visible.sync="setPasswdVisible" :close-on-click-modal="false" width="60%">
            <el-form :model="passwdData" label-width="100px" ref="passwdForm" label-position="left">
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="密码" prop="passwd" required>
                            <el-input size="small" v-model="passwdData.passwd"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click.native="formCancel('passwdForm')">取消</el-button>
                <el-button type="primary" @click.native="setPasswdSubmit" :loading="setPasswdLoading">提交</el-button>
            </div>
        </el-dialog>
    </section>
</template>

<script>
import util from "../../common/js/util";
import model from "../../common/js/model";
import gconfig from "../../api/gconfig";
import { postRequest, formatTimestamp} from "../../api/api";

export default {
    data() {
        return {
            filters: {
                accountName: "",
            },

            page: 1,
            pageSize: 100,
            total: 1,
            dataList: [],
            dataLoading: false,

            formVisible: false,
            formLoading: false,
            formType: 0,
            setPasswdVisible: false,
            setPasswdLoading: false,

            formData: {
                id: 0,
                name: "",
                passwd: "", 
                login_ip: "",
                login_time: 0,
                create_time: 0,
                account_type: 0,
                privileges: [],
                domains: [],
            },
            passwdData: {
                passwd: "",
            },

            domainOptions: [],
            privilegeOptions: [],
            accountType: gconfig.accountType,
            accountTypeOptions: [
                {label: "客户", value: gconfig.accountType.guest},
                {label: "管理", value: gconfig.accountType.admin},
                {label: "运营", value: gconfig.accountType.staff},
            ],
        }
    },

    methods: {
        formatTime(row, column, cellValue) {
            if (cellValue == 0) {
                return "0000-00-00 00:00:00"
            }
            return formatTimestamp(cellValue);
        },

        formatPrivileges(row, column, cellValue) {
            let nameList = []
            for (let val of cellValue) {
                let found = false;
                for (let item of this.privilegeOptions) {
                    if (item.value == val) {
                        nameList.push(item.label);
                        found = true;
                        break
                    }
                }
                if (!found) {
                    nameList.push(val);
                }
            }
            return nameList.join(",");
        },

        formatDomains(row, column, cellValue) {
            return cellValue.join(",");
        },

        handleAdminList() {
            let params = {
                page: this.page,
                pageSize: this.pageSize,
                accountName: this.filters.accountName,
            }
            this.dataLoading = true;
            let queryModel = model.GetQueryModel("AccountMgrCtrl", "GetAdminList", params);
            postRequest(queryModel).then(res => {
                this.dataLoading = false;

                if (res.data.msg !== "") {
                    this.$message.warning(res.data.msg);
                    return;
                }
                if (this.page === 1) {
                    this.total = res.data.total;
                }
                this.dataList = res.data.datas;
                console.log("GetAdminList", this.dataList);

            });
        },

        handlePrivilegeList() {
            let params = {}
            let queryModel = model.GetQueryModel("AccountMgrCtrl", "GetPrivilegeList", params);
            postRequest(queryModel).then(res => {
                if (res.data.msg !== "") {
                    this.$message.warning(res.data.msg);
                    return;
                }
                this.privilegeOptions = res.data.datas;
                console.log("handlePrivilegeList", this.privilegeOptions);

            }).catch(err => {
                this.$message.warning(err);
            });
        },

        handleDomainList() {
            let params = {}
            let queryModel = model.GetQueryModel("DomainMgrCtrl", "GetAllDomainList", params);
            postRequest(queryModel).then(res => {
                if (res.data.msg !== "") {
                    this.$message.warning(res.data.msg);
                    return;
                }
                for (let dm of res.data.datas) {
                    this.domainOptions.push({label:dm.domamin, value:dm.domain});
                }
                console.log("handleDomainList", this.domainOptions);

            }).catch(err => {
                this.$message.warning(err);

            });
        },

        formSubmit() {
            this.$refs.form.validate(valid => {
                if (valid) {
                    this.$confirm("确认提交吗？", "提示", {}).then(() => {
                        this.formLoading = true;
                        let params = Object.assign({}, this.formData);
                        var queryModel = model.GetQueryModel("AccountMgrCtrl", "UpdateAdmin", params);
                        postRequest(queryModel).then(res => {
                            this.formLoading = false;
                            if (!util.isEmpty(res.data.msg)) {
                                this.$message.warning(res.data.msg);
                                return;
                            } 
                            this.$message.success("操作成功");
                            this.formVisible = false;
                            this.$refs.form.resetFields();
                            this.handleAdminList();
                        });
                    });
                }
            });
        },

        handleAdd() {
            this.formType = 1;
            this.formVisible = false;
            this.formLoading = false;
            this.formData = {
                id: 0,
                name: "",
                passwd: "", 
                login_ip: "",
                login_time: 0,
                create_time: 0,
                account_type: 0,
                privileges: [],
                domains: [],
            },
            this.formVisible = true;
        },

        handleEdit(row) {
            this.formType = 2;
            this.formVisible = false;
            this.formLoading = false;
            Object.assign(this.formData, row)
            this.formVisible = true;
        },

        handleDelete: function (row) {
            this.$confirm("确认删除吗?", "提示", {type: "warning"}).then(() => {
                let params = {
                    id: row.id,
                };
                var queryModel = model.GetQueryModel("AccountMgrCtrl", "DeleteAdmin", params);
                postRequest(queryModel).then(res => {
                    if (!util.isEmpty(res.data.msg)) {
                        this.$message({ message: res.data.msg, type: "warning" });
                        return;
                    } 
                    this.$message.success("删除成功");
                    this.handleAdminList();
                });
            });
        },

        handleSetPasswd() {
            this.setPasswdLoading = false
            this.setPasswdVisible = true
            this.passwdData = { passwd: "" }
        },

        setPasswdSubmit() {
            this.$refs.passwdForm.validate(valid => {
                if (valid) {
                    this.$confirm("确认提交吗？", "提示", {}).then(() => {
                        this.setPasswdLoading = true
                        let params = Object.assign({}, this.passwdData);
                        var queryModel = model.GetQueryModel("AccountMgrCtrl", "CalculatePasswd", params);
                        postRequest(queryModel).then(res => {
                            this.setPasswdLoading = false
                            if (!util.isEmpty(res.data.msg)) {
                                this.$message.warning(res.data.msg);
                                return;
                            } 
                            this.setPasswdVisible = false;
                            Object.assign(this.formData, {passwd: res.data.datas});
                        });
                    });
                }
            });
        },

        formCancel(ref) {
            if (ref === "form") {
                this.formVisible = false;
            }
            if (ref === "passwdForm") {
                this.setPasswdVisible = false;
            }
            this.$refs[ref].resetFields();
        },

        handleCurrentChange(page) {
            this.page = page;
            this.handleAdminList();
        },

        handleSizeChange(val) {
            this.pageSize = val;
            this.handleAdminList();
        },
    },
    mounted() {
        this.handleAdminList();
        this.handlePrivilegeList();
        this.handleDomainList();
    }
}
</script>
<style scoped>
</style>