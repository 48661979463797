<template>
    <section>
        <!-- 列表 -->
        <template>
            <el-table :data="dataList" stripe highlight-current-row v-loading="dataLoading" size="small" height="800"
                style="width: 100%;">
                <el-table-column prop="platform" label="平台" width="100" :formatter="formatPlatform"></el-table-column>>
                <el-table-column prop="uc_id" label="账号id" width="150"></el-table-column>
                <el-table-column prop="uc_name" label="账号名" width="150"></el-table-column>
                <el-table-column prop="master_uid" label="管家id" width="150"></el-table-column>
                <el-table-column prop="master_name" label="管家名" width="150"></el-table-column>
                <el-table-column prop="create_time" label="创建时间" width="150" :formatter="formatTime"></el-table-column>
            </el-table>
            <!-- 翻页 -->
            <el-col :span="24" class="toolbar">
                <el-pagination layout="total, sizes, prev, pager, next" :current-page="this.page"
                    :total="total"
                    :page-size="pageSize" 
                    @current-change="handleCurrentChange" 
                    @size-change="handleSizeChange"
                    :page-sizes="[100,200,400]"
                    style="float:right;">
                </el-pagination>
            </el-col> 
        </template>
    </section>
</template>

<script>
import util from "../../common/js/util";
import gconfig from "../../api/gconfig";
import model from "../../common/js/model";
import { postRequest, formatTimestamp} from "../../api/api";

export default {
    data() {
        return {
            page: 1,
            pageSize: 100,
            total: 1,
            dataList: [],
            dataLoading: false,

            platformOptions: [],
        }
    },

    methods: {
        formatTime(row, column, cellValue) {
            if (cellValue == 0) {
                return "0000-00-00 00:00:00"
            }
            return formatTimestamp(cellValue);
        },

        formatPlatform(row, column, cellValue) {
            for (let item of this.platformOptions) {
                if (item.value == cellValue) {
                    if (gconfig.isBaidu(item.label)) {
                        return "百度";
                    }
                    return item.label;
                }
            }
            if (cellValue == 0) {
                return "未设置:"+cellValue
            }
            return "未知:"+cellValue
        },

        handleList() {
            let params = {
                page: this.page,
                pageSize: this.pageSize,
            }
            this.dataLoading = true;
            let queryModel = model.GetQueryModel("AdvAccountCtrl", "GetList", params);
            postRequest(queryModel).then(res => {
                this.dataLoading = false;

                if (res.data.msg !== "") {
                    this.$message.warning(res.data.msg);
                    return;
                }
                if (this.page === 1) {
                    this.total = res.data.total;
                }
                this.dataList = res.data.datas;
                console.log("GetList", this.dataList);

            });
        },

        handleCurrentChange(page) {
            this.page = page;
            this.handleList();
        },

        handleSizeChange(val) {
            this.pageSize = val;
            this.handleList();
        },
    },
    mounted() {
        this.dataLoading = true;
        util.getPlatformOptions(this).then(res => {
            this.dataLoading = false;
            this.platformOptions = res;
            this.handleList();
        });
    }
}
</script>
<style scoped>
</style>