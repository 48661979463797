import vue from "vue";
import app from "./app";
import elementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import vueRouter from "vue-router";
import store from "./vuex/store";
import vuex from "vuex";
import { routes, routeSize} from "./routes";
import "font-awesome/css/font-awesome.min.css";
import { initMenu } from "./api/api";
import { genNewMenu } from "./common/js/routes";
import autoHeight from './common/js/auto-height';
vue.directive('auto-height',autoHeight)

import "./styles/base.scss";
import "element-ui/lib/theme-chalk/index.css";

// 查看图片插件c
import vueDirectiveImagePreviewer from "vue-directive-image-previewer";
import "vue-directive-image-previewer/dist/assets/style.css";
vue.use(vueDirectiveImagePreviewer);

// 引入导出Excel表格的组件和工具库
import exportFile from "@/components/ExportFile";
vue.component("export-file", exportFile);

import util from "@/common/js/util";
import wtfk from "./watermark"
vue.prototype.util = util;

vue.use(elementUI);
vue.use(vueRouter);
vue.use(vuex);

var router = new vueRouter({
  routes,
});

//router.beforeEach 是vue-router 的全局守卫，它会在路由跳转前执行。 该方法接收三个参数： to: 即将要进入的目标路由对象 from: 当前导航正要离开的路由
router.beforeEach((to, from, next) => {
  console.log("router from:", from, "to:", to);
  if (to.path == "/login") {
    localStorage.removeItem("USER");
    localStorage.removeItem("ROUTER_DATA");
    initMenu();
  }

  //校验用户信息
  let user = null;
  let userJson = localStorage.getItem("USER");
  if (userJson) {
    try {
      user = JSON.parse(userJson);
    } catch(e) {
      console.log(e, userJson);
    }
  }
  if (!user && to.path != "/login") {
    next({ path: "/login" });
  }
  localStorage.setItem("ROUTE_PATH", to.path);

  //生成路由
  let routerDatas = null;
  let routerDataJson = localStorage.getItem("ROUTER_DATA");
  if (routerDataJson) {
    try {
      routerDatas = JSON.parse(routerDataJson);
    } catch(e) {
      console.log(e, routerDataJson);
    }
  }
  if (routerDatas && routes.length <= routeSize) {
    genNewMenu(router, routerDatas);
    router.push({ path: to.path });
  }

  let loginName = "nologin";
  if (user) {
    loginName = user.name;
  }
  wtfk.load({
    watermark_txt:  `${loginName}\n${new Date().toDateString() + new Date().toLocaleTimeString()}`,
    watermark_width: 200,
    watermark_alpha: 0.1
  })

  next();
});

new vue({
  router,
  store,
  render: (h) => h(app),
}).$mount("#app");
