
export default {
    GetQueryModel : function (obj, func, param){
        var Model = {};
        Model.Obj = obj;
        Model.Func = func;
        Model.Param = param;
        return Model
    },
    UploadUrl : "/srv/tianxin/htdocs/upload/admin/"
};
