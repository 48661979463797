import axios from 'axios';
import { param } from 'jquery';
import { routes, routeSize, menus } from '../routes'
import Vue from 'vue'

var env_uri = `/vueAdmin/myAdmin`;
var prod_uri = `/vueAdmin/myAdmin`;
var env = process.env.NODE_ENV;
const ADMIM_RELOGIN = 1001; // 重新登录；
const ADMIM_NO_PREMISSION = 1002; // 没有权限；
var dev_bg_color = "#BABBB7";
var prod_bg_color = "#C1CDC1";

const env_client_id = "5r0p8T0bPXY3IGTQm6bdNjqvJV06A0XV";
const env_redirect_uri = "http://localhost:8089/";

export const postOauth2 = params => {
    let uri = "";
    if (env === "development") {
        uri = env_uri;
    } else {
        uri = prod_uri;
    }
    if (env === "development") {
        params.Param["client_id"] = env_client_id
        params.Param["redirect_uri"] = env_redirect_uri
    }
    const res = axios.post(uri, params)
    res.then((ret) => { })
    return res
}

export const getUser = function () {
    let userStr = localStorage.getItem('USER');
    var urlRoot = window.document.location.href;
    if (userStr) {
        let user = JSON.parse(userStr);
        return user;
    }
    return null;
}

export const postRequest = function (params) {
    var uri = "";
    if (env === "development") {
        uri = env_uri;
    } else {
        uri = prod_uri;
    }

    params.Param["access_account_id"] = 0;
    params.Param["access_account_name"] = "";
    params.Param["access_path"] = "";

    let user = getUser();
    if (user != null) {
        params.Param["access_account_name"] = user.name;
        params.Param["access_account_id"] = user.id;
    }
    var urlRoot = window.document.location.href;
    let access_path = urlRoot.substring(urlRoot.lastIndexOf("/"), urlRoot.length).replace(/\?.*?$/, "")
    params.Param["access_path"] = access_path


    var access_token = localStorage.getItem("ACCESS_TOKEN") || "";
    var opt = {
        headers: {
            authorization: `${access_token}`
        }
    };

    var res = axios.post(uri, params, opt)
    res.then((ret) => {
        if (ret.data.code === ADMIM_RELOGIN) {
            localStorage.removeItem("USER");
            Vue.prototype.$message({
                message: "登陆已失效，请重新登陆",
                type: 'warning'
            });
            setTimeout(() => location.reload(), 1000);
        }
    }).catch(() => { })
    return res;
};

export const initMenu = () => {
    //最后添加
    while (routes.length > routeSize) {
        let menu = routes.pop()
    }
};


export const handleResToLogin = (router, code) => {
    if (code == 4444) {  //自动跳转到登录页面
        router.push('/login');
    }
}

// 格式化日期对象
export const dateFormat = (date) => {
    if (date == null) {
        date = new Date()
        date.getFullYear()
    }

    var y = date.getFullYear();
    var m = date.getMonth() + 1;
    m = m < 10 ? ('0' + m) : m;
    var d = date.getDate();
    d = d < 10 ? ('0' + d) : d;
    var h = date.getHours();
    h = h < 10 ? ('0' + h) : h;
    var minute = date.getMinutes();
    var second = date.getSeconds();
    minute = minute < 10 ? ('0' + minute) : minute;
    second = second < 10 ? ('0' + second) : second;

    return y + '-' + m + '-' + d + ' ' + h + ':' + minute + ':' + second;
}
// 格式化日期对象2
export const dateFormat2 = (date) => {
    if (date == null) {
        date = new Date()
        date.getFullYear()
    }

    var y = date.getFullYear();
    var m = date.getMonth() + 1;
    m = m < 10 ? ('0' + m) : m;
    var d = date.getDate();
    d = d < 10 ? ('0' + d) : d;
    var h = date.getHours();
    h = h < 10 ? ('0' + h) : h;
    var minute = date.getMinutes();
    var second = date.getSeconds();
    minute = minute < 10 ? ('0' + minute) : minute;
    second = second < 10 ? ('0' + second) : second;
    return y+m+d+h+minute+second;
}


// 前preDays天0点
export const getDotTime = (preDays) => {
    if (!preDays) {
        preDays = 0;
    }
    return new Date(new Date().setHours(0,0,0,0)-preDays*24*60*60*1000).getTime() / 1000;
}

// 当前时间戳
export const getTimestamp = (strTime) => {
    return new Date(strTime).getTime() / 1000;
}

// 时间戳转日期时间
export const formatTimestamp = (seconds) => {
    if (seconds == 0) {
        return "";
    }
    function add0(m) {
        return m < 10 ? '0' + m : m;
    }

    var time = new Date(seconds * 1000);
    var y = time.getFullYear();
    var m = time.getMonth() + 1;
    var d = time.getDate();
    var h = time.getHours();
    var mm = time.getMinutes();
    var s = time.getSeconds();
    return y + '-' + add0(m) + '-' + add0(d) + ' ' + add0(h) + ':' + add0(mm) + ':' + add0(s);
}

export const getCurrentMonth = () => {

    var date = new Date()
    date.getFullYear()

    var y = date.getFullYear();
    var m = date.getMonth() + 1;
    m = m < 10 ? ('0' + m) : m;

    return y + m;
}

export const getBgColor = () => {
    var bg_color = dev_bg_color;
    if (location.hostname.startsWith("admin") === true) {
        bg_color = prod_bg_color;
    }
    return bg_color;
}