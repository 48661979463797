<template>
    <section>
        <!-- 选择栏 -->
        <el-col :span="24" class="toolbar" style="padding-bottom: 0; padding-left: 5px;">
            <el-form :inline="true" :model="filters">
                <el-form-item label="账号">
                    <el-select size="small" v-model="filters.accountId" value="" filterable clearable>
                        <el-option v-for="item in guestAccountOptions" :key="item.value" :value="item.value" :label="item.label"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" size="small" v-on:click="handleList()">查询</el-button>
                    <el-button size="small" v-on:click="handleAdd()">添加</el-button>
                </el-form-item>
            </el-form>
        </el-col>

        <!-- 列表 -->
        <template>
            <el-table :data="dataList" stripe highlight-current-row v-loading="dataLoading" size="small" height="800"
                style="width: 100%;">
                <el-table-column prop="account_id" label="账号id" width="100"></el-table-column>
                <el-table-column prop="account_name" label="账号名" width="150"></el-table-column>
                <el-table-column prop="secret" label="访问密钥" width="200"></el-table-column>
                <el-table-column prop="create_time" label="创建时间" width="200" :formatter="formatTime"></el-table-column>
                <el-table-column label="操作" min-width="200" fixed="right">
                    <template slot-scope="scope">
                        <el-button size="small" @click="handleEdit(scope.row)">修改</el-button>
                        <el-button type="danger" size="small" @click="handleDelete(scope.row)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <!-- 翻页 -->
            <el-col :span="24" class="toolbar">
                <el-pagination layout="total, sizes, prev, pager, next" :current-page="this.page"
                    :total="total"
                    :page-size="pageSize" 
                    @current-change="handleCurrentChange" 
                    @size-change="handleSizeChange"
                    :page-sizes="[100,200,400]"
                    style="float:right;">
                </el-pagination>
            </el-col> 
        </template>

        <!-- 添加/修改 -->
        <el-dialog title="添加/修改" :visible.sync="formVisible" :close-on-click-modal="false" width="60%">
            <el-form :model="formData" label-width="100px" ref="form" label-position="left">
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="账号" prop="account_id" required >
                            <el-select size="small" v-model.number="formData.account_id" value="" filterable :disabled="formType==2">
                                <el-option v-for="item in guestAccountOptions" :key="item.value" :value="item.value" :label="item.label"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="访问密钥" prop="secret" required >
                            <el-input size="small" v-model="formData.secret"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click.native="formCancel('form')">取消</el-button>
                <el-button type="primary" @click.native="handleSubmit" :loading="formLoading">提交</el-button>
            </div>
        </el-dialog>
    </section>
</template>

<script>
import util from "../../common/js/util";
import model from "../../common/js/model";
import { postRequest, formatTimestamp} from "../../api/api";

export default {
    data() {
        return {
            filters: {
                accountId: "",
            },

            page: 1,
            pageSize: 100,
            total: 1,
            dataList: [],
            dataLoading: false,

            formType : 0,
            formVisible: false,
            formLoading: false,

            formData: {
                account_id: "",
                account_name: "",
                secret: "",
                create_time: 0,
            },

            guestAccountOptions: [],
        }
    },

    methods: {
        formatTime(row, column, cellValue) {
            if (cellValue == 0) {
                return "0000-00-00 00:00:00"
            }
            return formatTimestamp(cellValue);
        },

        getAccountName(accountId) {
            for (let item of this.guestAccountOptions) {
                if (item.value == accountId) {
                    return item.label;
                }
            }
            return "";
        },

        handleList() {
            let params = {
                page: this.page,
                pageSize: this.pageSize,
                accountId: this.filters.accountId,
            }
            this.dataLoading = true;
            let queryModel = model.GetQueryModel("AccountMgrCtrl", "GetGuestSecretList", params);
            postRequest(queryModel).then(res => {
                this.dataLoading = false;

                if (res.data.msg !== "") {
                    this.$message.warning(res.data.msg);
                    return;
                }
                if (this.page === 1) {
                    this.total = res.data.total;
                }
                this.dataList = res.data.datas;
                console.log("handleList", this.dataList);

            });
        },

        handleSubmit() {
            this.$refs.form.validate(valid => {
                if (valid) {
                    this.$confirm("确认提交吗？", "提示", {}).then(() => {
                        this.formLoading = true;
                        let params = Object.assign({}, this.formData);
                        params.account_name = this.getAccountName(params.account_id);
                        var queryModel = model.GetQueryModel("AccountMgrCtrl", "SaveGuestSecret", params);
                        postRequest(queryModel).then(res => {
                            this.formLoading = false;
                            if (!util.isEmpty(res.data.msg)) {
                                this.$message.warning(res.data.msg);
                                return;
                            } 
                            this.$message.success("操作成功");
                            this.formVisible = false;
                            this.$refs.form.resetFields();
                            this.handleList();
                        });
                    });
                }
            });
        },

        handleAdd() {
            this.formType = 1;
            this.formVisible = false;
            this.formLoading = false;
            this.formData = {
                account_id: "",
                account_name: "",
                secret: "",
                create_time: 0,
            },
            this.formVisible = true;
        },

        handleEdit(row) {
            this.formType = 2;
            this.formVisible = false;
            this.formLoading = false;
            this.formData = Object.assign({}, row);
            this.formVisible = true;
        },


        handleDelete: function (row) {
            this.$confirm("确认删除吗?", "提示", {type: "warning"}).then(() => {
                let params = {
                    accountId: row.account_id,
                };
                var queryModel = model.GetQueryModel("AccountMgrCtrl", "DeleteGuestSecret", params);
                postRequest(queryModel).then(res => {
                    if (!util.isEmpty(res.data.msg)) {
                        this.$message({ message: res.data.msg, type: "warning" });
                        return;
                    } 
                    this.$message.success("删除成功");
                    this.handleList();
                });
            });
        },

        formCancel(ref) {
            if (ref === "form") {
                this.formVisible = false;
            }
            this.$refs[ref].resetFields();
        },

        handleCurrentChange(page) {
            this.page = page;
            this.handleList();
        },

        handleSizeChange(val) {
            this.pageSize = val;
            this.handleList();
        },

        handleAllGuestAccountList() {
            let params = {};
            let queryModel = model.GetQueryModel("AccountMgrCtrl", "GetAllGuestAccountList", params);
            postRequest(queryModel).then(res => {
                if (res.data.msg !== "") {
                    this.$message.warning(res.data.msg);
                    return;
                }
                for (let val of res.data.datas) {
                    this.guestAccountOptions.push({label:val.name, value:val.id});
                }
                console.log("handleGuestAccountList", this.guestAccountOptions);

            });
        },

    },
    mounted() {
        this.handleAllGuestAccountList();
        this.handleList();
    }
}
</script>
<style scoped>
</style>