<template>
    <section>
        <!-- 选择栏 -->
        <el-col :span="24" class="toolbar" style="padding-bottom: 0; padding-left: 5px;">
            <el-form :inline="true" :model="filters">
                <el-form-item label="页面名字:">
                    <el-input size="small" v-model="filters.name" clearable></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" size="small" v-on:click="handlePageList()">查询</el-button>
                </el-form-item>
            </el-form>
        </el-col>

        <!-- 列表 -->
        <template>
            <el-table :data="dataList" stripe highlight-current-row v-loading="dataLoading" size="small" height="800"
                style="width: 100%;">
                <el-table-column prop="page_type" label="页面类型" width="200">
                    <template slot-scope="scope">
                        <span v-if="scope.row.page_type==0" style="color:green;">普通页面</span>
                        <span v-if="scope.row.page_type==1" style="color:orange;">审核页面</span>
                    </template>
                </el-table-column>
                <el-table-column prop="page_name" label="页面名字" width="200"></el-table-column>
                <el-table-column prop="page_path" label="页面目录" width="400"></el-table-column>
            </el-table>
            <!-- 翻页 -->
            <el-col :span="24" class="toolbar">
                <el-pagination layout="total, sizes, prev, pager, next" :current-page="this.page"
                    :total="total"
                    :page-size="pageSize" 
                    @current-change="handleCurrentChange" 
                    @size-change="handleSizeChange"
                    :page-sizes="[100,200,400]"
                    style="float:right;">
                </el-pagination>
            </el-col> 
        </template>
    </section>
</template>

<script>
import util from "../../common/js/util";
import model from "../../common/js/model";
import { postRequest, formatTimestamp} from "../../api/api";

export default {
    data() {
        return {
            filters: {
                name: "",
            },

            page: 1,
            pageSize: 100,
            total: 1,
            dataList: [],
            dataLoading: false,

            formVisible: false,
            formLoading: false,
            formType: 0,

            formData: {
                page_type: 0,
                page_name: "",
                page_path: "",
            },

            pageTypeOptions: [
                {label:"普通页面", value:0},
                {label:"审核页面", value:1},
            ],

        }
    },

    methods: {
        formatTime(row, column, cellValue) {
            if (cellValue == 0) {
                return "0000-00-00 00:00:00"
            }
            return formatTimestamp(cellValue);
        },

        formatPageType(row, column, cellValue) {
            for (let page of this.pageTypeOptions) {
                if (page.value == cellValue) {
                    return page.label
                }
            }
            return "unknown"
        },

        handlePageList() {
            let params = {
                page: this.page,
                pageSize: this.pageSize,
                name: this.filters.name,
            }
            this.dataLoading = true;
            let queryModel = model.GetQueryModel("PageMgrCtrl", "GetPageList", params);
            postRequest(queryModel).then(res => {
                this.dataLoading = false;

                if (res.data.msg !== "") {
                    this.$message.warning(res.data.msg);
                    return;
                }
                if (this.page === 1) {
                    this.total = res.data.total;
                }
                this.dataList = res.data.datas;
                console.log("GetPageList", this.dataList);

            });
        },

        handleCurrentChange(page) {
            this.page = page;
            this.handlePageList();
        },

        handleSizeChange(val) {
            this.pageSize = val;
            this.handlePageList();
        },
    },
    mounted() {
        this.handlePageList();
    }
}
</script>
<style scoped>
</style>