//test
export const getCount = state => {
    return state.count
}

export const dyactionMap = state => {
    const dyactionMapState = state.deepLinkConfig.find(item => item.description === "dyactionMap")
    let dyactionMap = {}
    try {
        dyactionMap = JSON.parse(dyactionMapState.name)
    } catch(e) {
        console.log(e)
    }
    return dyactionMap
}

// 获取deeplink动态的field
export const dynaFormFields = state => {
    const dynaFormFieldsState = state.deepLinkConfig.filter(item => {
        return item.description.match(/^field_/)
    })
    
    const res = dynaFormFieldsState.reduce((res, item) => {
        try {
            const config = JSON.parse(item.name)
            const key = item.description.replace(/^field_/, "")
            if (typeof config === 'object') {
                res[key] = config
            }
        }catch(e) {
            console.log(e)
        }finally{
            return res
        }
    }, {})
    
    return res
}

// 获取deeplink动态的rule
export const dynaFormRules = state => {
    const dynaFormFieldsState = state.deepLinkConfig.filter(item => {
        return item.description.match(/^rule_/)
    })
    
    const res = dynaFormFieldsState.reduce((res, item) => {
        try {
            const config = JSON.parse(item.name)
            const key = item.description.replace(/^rule_/, "")
            if (typeof config === 'object') {
                res[key] = config
            }
        }catch(e) {
            console.log(e)
        }finally{
            return res
        }
    }, {})
    
    return res
}

// 获取deeplink动态的默认数据
export const dynaFormDefaults = state => {
    const dynaFormFieldsState = state.deepLinkConfig.filter(item => {
        return item.description.match(/^default_/)
    })
    
    const res = dynaFormFieldsState.reduce((res, item) => {
        try {
            const config = JSON.parse(item.name)
            const key = item.description.replace(/^default_/, "")
            if (typeof config === 'object') {
                res[key] = config
            }      
        }catch(e) {
            console.log(e)
        }finally{
            return res
        }
    }, {})
    
    return res
}

export const dynaDeeplinkURI = state => {
    const deeplinkURI = state.deepLinkConfig.find(item => item.description === "deeplinkURI")
    
    return deeplinkURI ? deeplinkURI.name : "mita://www.mitayouxi.com";
}

export const dynaDeeplinkIcon = state => {
    const deeplinkIcon = state.deepLinkConfig.find(item => item.description === "deeplinkIcon")
    return deeplinkIcon ? deeplinkIcon.name : 'https://res.kaiheicloud.com/upload/pre/resources/picture/default/me_img_touxiang_default1.png';
}