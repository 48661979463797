var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c(
        "el-col",
        {
          staticClass: "toolbar",
          staticStyle: { "padding-bottom": "0", "padding-left": "5px" },
          attrs: { span: 24 },
        },
        [
          _c(
            "el-form",
            { attrs: { inline: true, model: _vm.filters } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "页面名字:" } },
                [
                  _c("el-input", {
                    attrs: { size: "small", clearable: "" },
                    model: {
                      value: _vm.filters.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.filters, "name", $$v)
                      },
                      expression: "filters.name",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "small" },
                      on: {
                        click: function ($event) {
                          return _vm.handlePageList()
                        },
                      },
                    },
                    [_vm._v("查询")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      [
        _c(
          "el-table",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.dataLoading,
                expression: "dataLoading",
              },
            ],
            staticStyle: { width: "100%" },
            attrs: {
              data: _vm.dataList,
              stripe: "",
              "highlight-current-row": "",
              size: "small",
              height: "800",
            },
          },
          [
            _c("el-table-column", {
              attrs: { prop: "page_type", label: "页面类型", width: "200" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      scope.row.page_type == 0
                        ? _c("span", { staticStyle: { color: "green" } }, [
                            _vm._v("普通页面"),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      scope.row.page_type == 1
                        ? _c("span", { staticStyle: { color: "orange" } }, [
                            _vm._v("审核页面"),
                          ])
                        : _vm._e(),
                    ]
                  },
                },
              ]),
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: { prop: "page_name", label: "页面名字", width: "200" },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: { prop: "page_path", label: "页面目录", width: "400" },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "el-col",
          { staticClass: "toolbar", attrs: { span: 24 } },
          [
            _c("el-pagination", {
              staticStyle: { float: "right" },
              attrs: {
                layout: "total, sizes, prev, pager, next",
                "current-page": this.page,
                total: _vm.total,
                "page-size": _vm.pageSize,
                "page-sizes": [100, 200, 400],
              },
              on: {
                "current-change": _vm.handleCurrentChange,
                "size-change": _vm.handleSizeChange,
              },
            }),
          ],
          1
        ),
      ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }