import Vue from 'vue'
import Vuex from 'vuex'
import * as actions from './actions'
import * as getters from './getters'

Vue.use(Vuex)

// 应用初始状态
const state = {
    count: 0,
    hasDeepLinkConfig: false,
    deepLinkConfig: [],
};

// 定义所需的 mutations
const mutations = {
    INCREMENT:function(state) {
        state.count++
    },
    DECREMENT:function(state) {
        state.count--
    },

    ADD_ROUTERS:function(state) {
        state.count++
    },

    SET_DEEPLINK_CONFIG:function(state, val) {
        state.deepLinkConfig = val
    },

    SET_HAS_DEEPLINK_CONFIG:function(state, val) {
        state.hasDeepLinkConfig = val
    }
};

// 创建 store 实例
export default new Vuex.Store({
    actions,
    getters,
    state,
    mutations
})