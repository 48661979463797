<template>
    <section>
        <!-- 选择栏 -->
        <el-col :span="24" class="toolbar" style="padding-bottom: 0; padding-left: 5px;">
            <el-form :inline="true" :model="filters" size="small">
                <el-form-item label="推广账号" prop="advAccountName" >
                    <el-select size="small" v-model="filters.advAccountName" value="" style="width:140px" filterable clearable>
                        <el-option v-for="item in bindAdvAccountOptions" :key="item.value" :value="item.value"
                            :label="item.label"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="客户账号" prop="guestAccountId" v-if="!gconfig.isGuest()">
                    <el-select size="small" v-model.number="filters.guestAccountId" value="" style="width:140px" filterable clearable>
                        <el-option v-for="item in guestAccountOptions" :key="item.value" :value="item.value"
                            :label="item.label"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="运营账号" prop="staffAccountId" v-if="gconfig.isAdmin()" >
                    <el-select size="small" v-model.number="filters.staffAccountId" value="" style="width:140px" filterable clearable>
                        <el-option v-for="item in staffAccountOptions" :key="item.value" :value="item.value"
                            :label="item.label"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="成交日期" >
                    <el-date-picker size="small" v-model="filters.timeRange" type="daterange" value-format="timestamp"
                        range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" style="width:250px">
                    </el-date-picker>
                </el-form-item>
                <el-form-item >
                    <el-button type="primary" size="small" v-on:click="handleList()" style="width:50px">查询</el-button>
                </el-form-item>
            </el-form>
        </el-col>

        <!-- 列表 -->
        <template>
            <el-table :data="dataList" stripe highlight-current-row v-loading="dataLoading" size="small" height="800"
                style="width: 100%;">

                <el-table-column prop="id" label="订单号" width="100"></el-table-column>
                <el-table-column prop="deal_time" label="成交时间" width="150" :formatter="formatTime"></el-table-column>
                <el-table-column prop="adv_account" label="推广账号" width="150">
                    <template slot-scope="scope">
                        <span v-if="scope.row.adv_account== ''">未绑定</span>
                        <span v-else style="color:green; font-weight: bold;" >{{ scope.row.adv_account }} </span>
                    </template>
                </el-table-column>
                <el-table-column prop="account" label="客户账号" width="100">
                    <template slot-scope="scope">
                        <span v-if="scope.row.account_guest == ''">未绑定</span>
                        <span v-else style="color:green; font-weight: bold;"> {{ scope.row.account_guest }} </span>
                    </template>
                </el-table-column>
                <el-table-column prop="account" label="运营账号" width="100" v-if="!gconfig.isGuest()" >
                    <template slot-scope="scope">
                        <span v-if="scope.row.account_staff == ''">未绑定</span>
                        <span v-else style="color:orange; font-weight: bold;"> {{ scope.row.account_staff }} </span>
                    </template>
                </el-table-column>
                <el-table-column prop="title" label="用户名" width="150" :show-overflow-tooltip="true"></el-table-column>
                <el-table-column prop="tel" label="电话" width="100"></el-table-column>
            </el-table>

            <!-- 翻页 -->
            <el-col :span="24" class="toolbar">
                <el-pagination layout="total, sizes, prev, pager, next" :current-page="this.page"
                    :total="total"
                    :page-size="pageSize" 
                    @current-change="handleCurrentChange" 
                    @size-change="handleSizeChange"
                    :page-sizes="[100,200,400]"
                    style="float:right;">
                </el-pagination>
            </el-col> 
        </template>
    </section>
</template>

<script>
import util from "../../common/js/util";
import gconfig from "../../api/gconfig";
import model from "../../common/js/model";
import { postRequest, formatTimestamp, getDotTime} from "../../api/api";

export default {
    data() {
        return {
            filters: {
                advAccountName: "",
                staffAccountId: "",
                guestAccountId: "",
                timeRange: [getDotTime() * 1000, getDotTime() * 1000],
            },

         
            total: 0,
            page: 1,
            pageSize: 100,
            dataList: [],
            dataLoading: false,

            accountOptions: [],
            gconfig: gconfig,

            bindAdvAccountOptions: [],
            guestAccountOptions: [],
            staffAccountOptions: [],

        }
    },

    methods: {
        formatTime(row, column, cellValue) {
            if (cellValue == 0) {
                return "0000-00-00 00:00:00"
            }
            return formatTimestamp(cellValue);
        },
        formatPlatform(row, column, cellValue) {
            for (let item of this.platformOptions) {
                if (item.value == cellValue) {
                    return item.label;
                }
            }
            return cellValue;
        },

        handleList() {
            let params = Object.assign({}, this.filters);
            params.page = this.page,
            params.pageSize = this.pageSize,

            this.dataLoading = true;
            let queryModel = model.GetQueryModel("OrderMgrCtrl", "GetDealOrderList", params);
            postRequest(queryModel).then(res => {
                this.dataLoading = false;

                if (res.data.msg !== "") {
                    this.$message.warning(res.data.msg);
                    return;
                }
                if (this.page === 1) {
                    this.total = res.data.total;
                }
                this.dataList = res.data.datas;
                console.log("handleList", this.dataList);
            });
        },

        handleCurrentChange(page) {
            this.page = page;
            this.handleList();
        },

        handleSizeChange(val) {
            this.pageSize = val;
            this.handleList();
        },

        handleStaffAccountList() {
            let params = {};
            let queryModel = model.GetQueryModel("AccountMgrCtrl", "GetStaffAccountList", params);
            postRequest(queryModel).then(res => {
                if (res.data.msg !== "") {
                    this.$message.warning(res.data.msg);
                    return;
                }
                for (let val of res.data.datas) {
                    this.staffAccountOptions.push({label:val.name, value:val.id});
                }
                console.log("handleStaffAccountList", this.staffAccountOptions);

            });
        },

        handleBindAdvAccountList() {
            let params = {}
            let queryModel = model.GetQueryModel("AdvAccountCtrl", "GetBindAdvAccountList", params);
            postRequest(queryModel).then(res => {
                if (res.data.msg !== "") {
                    this.$message.warning(res.data.msg);
                    return;
                }
                for (let val of res.data.datas) {
                    this.bindAdvAccountOptions.push({label:val.uc_name, value:val.uc_name});
                }
                console.log("handleBindAdvAccountList", this.bindAdvAccountOptions);

            });
        },

        handleGuestAccountList() {
            let params = {};
            let queryModel = model.GetQueryModel("AccountMgrCtrl", "GetGuestAccountList", params);
            postRequest(queryModel).then(res => {
                if (res.data.msg !== "") {
                    this.$message.warning(res.data.msg);
                    return;
                }
                for (let val of res.data.datas) {
                    this.guestAccountOptions.push({label:val.name, value:val.id});
                }
                console.log("handleGuestAccountList", this.guestAccountOptions);

            });
        },
    },
    mounted() {
        this.handleBindAdvAccountList();
        this.handleGuestAccountList();
        this.handleStaffAccountList();
        this.handleList();
    }
}
</script>
<style scoped>
</style>