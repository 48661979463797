var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c(
        "el-col",
        {
          staticClass: "toolbar",
          staticStyle: { "padding-bottom": "0", "padding-left": "5px" },
          attrs: { span: 24 },
        },
        [
          _c(
            "el-form",
            { attrs: { inline: true, model: _vm.filters } },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "推广账号", prop: "advAccountName" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "140px" },
                          attrs: {
                            size: "small",
                            value: "",
                            filterable: "",
                            clearable: "",
                          },
                          model: {
                            value: _vm.filters.advAccountName,
                            callback: function ($$v) {
                              _vm.$set(_vm.filters, "advAccountName", $$v)
                            },
                            expression: "filters.advAccountName",
                          },
                        },
                        _vm._l(_vm.bindAdvAccountOptions, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { value: item.value, label: item.label },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "客户账号", prop: "guestAccountId" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "140px" },
                          attrs: {
                            size: "small",
                            value: "",
                            filterable: "",
                            clearable: "",
                          },
                          model: {
                            value: _vm.filters.guestAccountId,
                            callback: function ($$v) {
                              _vm.$set(_vm.filters, "guestAccountId", $$v)
                            },
                            expression: "filters.guestAccountId",
                          },
                        },
                        _vm._l(_vm.guestAccountOptions, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { value: item.value, label: item.label },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.gconfig.isAdmin()
                    ? _c(
                        "el-form-item",
                        {
                          attrs: { label: "运营账号", prop: "staffAccountId" },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "180px" },
                              attrs: {
                                size: "small",
                                value: "",
                                filterable: "",
                                clearable: "",
                              },
                              model: {
                                value: _vm.filters.staffAccountId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.filters, "staffAccountId", $$v)
                                },
                                expression: "filters.staffAccountId",
                              },
                            },
                            _vm._l(_vm.staffAccountOptions, function (item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { value: item.value, label: item.label },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "平台", prop: "platformId" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "140px" },
                          attrs: {
                            size: "small",
                            value: "",
                            filterable: "",
                            clearable: "",
                          },
                          model: {
                            value: _vm.filters.platformId,
                            callback: function ($$v) {
                              _vm.$set(_vm.filters, "platformId", $$v)
                            },
                            expression: "filters.platformId",
                          },
                        },
                        _vm._l(_vm.platformOptions, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { value: item.value, label: item.label },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "域名", prop: "domainName" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "140px" },
                        attrs: { size: "small", clearable: "" },
                        model: {
                          value: _vm.filters.domainName,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "domainName", $$v)
                          },
                          expression: "filters.domainName",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", size: "small" },
                          on: {
                            click: function ($event) {
                              return _vm.handleDomainList()
                            },
                          },
                        },
                        [_vm._v("查询")]
                      ),
                      _vm._v(" "),
                      !_vm.gconfig.isGuest()
                        ? _c(
                            "el-button",
                            {
                              attrs: { size: "small" },
                              on: {
                                click: function ($event) {
                                  return _vm.handleAdd()
                                },
                              },
                            },
                            [_vm._v("添加")]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                { staticStyle: { "margin-top": "-10px" } },
                [
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            size: "mini",
                            type: "primary",
                            disabled: _vm.selections.length == 0,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.showBatchModify("normalpage")
                            },
                          },
                        },
                        [_vm._v("批量修改普通页")]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            size: "mini",
                            type: "primary",
                            disabled: _vm.selections.length == 0,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.showBatchModify("examinepage")
                            },
                          },
                        },
                        [_vm._v("批量修改审核页")]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            size: "mini",
                            type: "primary",
                            disabled: _vm.selections.length == 0,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.showBatchModify("resultpage")
                            },
                          },
                        },
                        [_vm._v("批量修改结果页")]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            size: "mini",
                            type: "primary",
                            disabled: _vm.selections.length == 0,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.showBatchModify("shieldip")
                            },
                          },
                        },
                        [_vm._v("批量修改屏蔽ip")]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            size: "mini",
                            type: "primary",
                            disabled: _vm.selections.length == 0,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.showBatchModify("shieldterminal")
                            },
                          },
                        },
                        [_vm._v("批量修改屏蔽终端")]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "" } },
                    [
                      _c(
                        "el-checkbox-group",
                        {
                          on: { change: _vm.setCheckedColumns },
                          model: {
                            value: _vm.checkedColumns,
                            callback: function ($$v) {
                              _vm.checkedColumns = $$v
                            },
                            expression: "checkedColumns",
                          },
                        },
                        _vm._l(this.columnGroups, function (column) {
                          return _c(
                            "el-checkbox",
                            { key: column, attrs: { label: column } },
                            [_vm._v(_vm._s(column))]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      [
        _c(
          "el-table",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.dataLoading,
                expression: "dataLoading",
              },
            ],
            staticStyle: { width: "100%" },
            attrs: {
              data: _vm.dataList,
              stripe: "",
              "highlight-current-row": "",
              size: "small",
              height: "775",
            },
            on: { "selection-change": _vm.selectionChange },
          },
          [
            _c("el-table-column", {
              attrs: { type: "selection", width: "55" },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: { prop: "adv_account", label: "推广账号", width: "150" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      scope.row.adv_account == ""
                        ? _c("span", [_vm._v("未绑定")])
                        : _c(
                            "span",
                            {
                              staticStyle: {
                                color: "green",
                                "font-weight": "bold",
                              },
                            },
                            [_vm._v(_vm._s(scope.row.adv_account) + " ")]
                          ),
                    ]
                  },
                },
              ]),
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                prop: "account_id_guest",
                label: "客户账号",
                width: "140",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      scope.row.account_id_guest == 0
                        ? _c("span", [_vm._v("未绑定")])
                        : _c(
                            "span",
                            {
                              staticStyle: {
                                color: "green",
                                "font-weight": "bold",
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.formatGuestAccountName(
                                    scope.row.account_id_guest
                                  )
                                ) + " "
                              ),
                            ]
                          ),
                    ]
                  },
                },
              ]),
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                prop: "account_id_staff",
                label: "运营账号",
                width: "140",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      scope.row.account_id_staff == 0
                        ? _c("span", [_vm._v("未绑定")])
                        : _c(
                            "span",
                            {
                              staticStyle: {
                                color: "orange",
                                "font-weight": "bold",
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.formatStaffAccountName(
                                    scope.row.account_id_staff
                                  )
                                ) + " "
                              ),
                            ]
                          ),
                    ]
                  },
                },
              ]),
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                prop: "platform",
                label: "平台",
                width: "100",
                formatter: _vm.formatPlatform,
              },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                prop: "normal_page_url",
                label: "普通页",
                width: "150",
                "show-overflow-tooltip": true,
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c(
                        "a",
                        {
                          attrs: {
                            target: "_blank",
                            href: scope.row.normal_page_url,
                          },
                        },
                        [_vm._v(_vm._s(scope.row.normal_page_url))]
                      ),
                    ]
                  },
                },
              ]),
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                prop: "result_page_id",
                label: "结果页",
                width: "100",
                "show-overflow-tooltip": true,
                formatter: _vm.formatResultPage,
              },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                prop: "shield_conf",
                label: "屏蔽配置",
                width: "200",
                "show-overflow-tooltip": true,
              },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: { prop: "domain", label: "域名", width: "180" },
            }),
            _vm._v(" "),
            _vm.checkedColumns.includes("其他")
              ? _c("el-table-column", {
                  attrs: {
                    prop: "examine_page_url",
                    label: "审核页",
                    width: "150",
                    "show-overflow-tooltip": true,
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "a",
                              {
                                attrs: {
                                  target: "_blank",
                                  href: scope.row.examine_page_url,
                                },
                              },
                              [_vm._v(_vm._s(scope.row.examine_page_url))]
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    2393392364
                  ),
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.checkedColumns.includes("其他")
              ? _c("el-table-column", {
                  attrs: {
                    prop: "shield_page_url",
                    label: "推广页",
                    width: "150",
                    "show-overflow-tooltip": true,
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "a",
                              {
                                attrs: {
                                  target: "_blank",
                                  href: scope.row.shield_page_url,
                                },
                              },
                              [_vm._v(_vm._s(scope.row.shield_page_url))]
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    1731032588
                  ),
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.checkedColumns.includes("其他")
              ? _c("el-table-column", {
                  attrs: {
                    prop: "dyn_script",
                    label: "动态脚本",
                    width: "120",
                    "show-overflow-tooltip": true,
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.checkedColumns.includes("其他")
              ? _c("el-table-column", {
                  attrs: {
                    prop: "create_time",
                    label: "创建时间",
                    width: "140",
                    formatter: _vm.formatTime,
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.checkedColumns.includes("其他")
              ? _c("el-table-column", {
                  attrs: {
                    prop: "app_key",
                    label: "key/token",
                    width: "100",
                    "show-overflow-tooltip": true,
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.checkedColumns.includes("其他")
              ? _c("el-table-column", {
                  attrs: {
                    prop: "app_secret",
                    label: "secret",
                    width: "100",
                    "show-overflow-tooltip": true,
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.checkedColumns.includes("其他")
              ? _c("el-table-column", {
                  attrs: {
                    prop: "jzqs",
                    label: "jzqs",
                    width: "100",
                    "show-overflow-tooltip": true,
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.checkedColumns.includes("其他")
              ? _c("el-table-column", {
                  attrs: { prop: "port", label: "端口", width: "50" },
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.checkedColumns.includes("其他")
              ? _c("el-table-column", {
                  attrs: {
                    prop: "spec_form_feedback_rate",
                    label: "全部表单回传比例",
                    width: "120",
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", [
                              _vm._v(
                                _vm._s(scope.row.spec_form_feedback_rate) + "%"
                              ),
                            ]),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    3902217071
                  ),
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.checkedColumns.includes("其他")
              ? _c("el-table-column", {
                  attrs: {
                    prop: "company_name",
                    label: "公司名字",
                    width: "120",
                    "show-overflow-tooltip": true,
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.checkedColumns.includes("其他")
              ? _c("el-table-column", {
                  attrs: {
                    prop: "company_addr",
                    label: "公司地址",
                    width: "120",
                    "show-overflow-tooltip": true,
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.checkedColumns.includes("其他")
              ? _c("el-table-column", {
                  attrs: {
                    prop: "company_phone",
                    label: "公司电话",
                    width: "120",
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.checkedColumns.includes("其他")
              ? _c("el-table-column", {
                  attrs: {
                    prop: "desc",
                    label: "描述",
                    width: "120",
                    "show-overflow-tooltip": true,
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: { label: "操作", "min-width": "240", fixed: "right" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c(
                        "el-button",
                        {
                          staticStyle: { width: "50px", "margin-left": "3px" },
                          attrs: { size: "small" },
                          on: {
                            click: function ($event) {
                              return _vm.handleEdit(scope.row)
                            },
                          },
                        },
                        [_vm._v("修改")]
                      ),
                      _vm._v(" "),
                      _vm.gconfig.isAdmin()
                        ? _c(
                            "el-button",
                            {
                              staticStyle: {
                                width: "50px",
                                "margin-left": "3px",
                              },
                              attrs: { type: "danger", size: "small" },
                              on: {
                                click: function ($event) {
                                  return _vm.handleDelete(scope.row)
                                },
                              },
                            },
                            [_vm._v("删除")]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          style:
                            "width: 80px; margin-left:3px;font-weight:bold;" +
                            (scope.row.feedback_rule == 1
                              ? "color:green"
                              : "color:gray"),
                          attrs: { size: "small" },
                          on: {
                            click: function ($event) {
                              return _vm.handleRule(scope.row)
                            },
                          },
                        },
                        [_vm._v(" 回传规则 ")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          style:
                            "width: 80px; margin-left: 3px;font-weight:bold;" +
                            (scope.row.feedback_deal_order == 1
                              ? "color:green"
                              : "color:gray"),
                          attrs: { size: "small" },
                          on: {
                            click: function ($event) {
                              return _vm.handleFeedbackDealOrder(scope.row)
                            },
                          },
                        },
                        [_vm._v("成交回传")]
                      ),
                      _vm._v(" "),
                      _vm.checkedColumns.includes("其他")
                        ? _c(
                            "el-button",
                            {
                              staticStyle: {
                                width: "50px",
                                "margin-left": "3px",
                              },
                              attrs: { size: "small" },
                              on: {
                                click: function ($event) {
                                  return _vm.handleNginx(scope.row)
                                },
                              },
                            },
                            [_vm._v("nginx")]
                          )
                        : _vm._e(),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "el-col",
          { staticClass: "toolbar", attrs: { span: 24 } },
          [
            _c("el-pagination", {
              staticStyle: { float: "right" },
              attrs: {
                layout: "total, sizes, prev, pager, next",
                "current-page": this.page,
                total: _vm.total,
                "page-size": _vm.pageSize,
                "page-sizes": [100, 200, 400],
              },
              on: {
                "current-change": _vm.handleCurrentChange,
                "size-change": _vm.handleSizeChange,
              },
            }),
          ],
          1
        ),
      ],
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.batchModifyTitle,
            visible: _vm.batchModifyVisible,
            "close-on-click-modal": false,
            width: "30%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.batchModifyVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "batchform",
              attrs: {
                model: _vm.batchForm,
                "label-width": "100px",
                "label-position": "left",
              },
            },
            [
              _vm.batchForm.modType == "normalpage"
                ? _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-select",
                        {
                          key: "1",
                          staticStyle: { width: "70%" },
                          attrs: { size: "small", value: "" },
                          model: {
                            value: _vm.batchForm.modValue,
                            callback: function ($$v) {
                              _vm.$set(_vm.batchForm, "modValue", $$v)
                            },
                            expression: "batchForm.modValue",
                          },
                        },
                        _vm._l(_vm.normalPageOptions, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { value: item.value, label: item.label },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.batchForm.modType == "examinepage"
                ? _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-select",
                        {
                          key: "2",
                          staticStyle: { width: "70%" },
                          attrs: { size: "small", value: "" },
                          model: {
                            value: _vm.batchForm.modValue,
                            callback: function ($$v) {
                              _vm.$set(_vm.batchForm, "modValue", $$v)
                            },
                            expression: "batchForm.modValue",
                          },
                        },
                        _vm._l(_vm.examinePageOptions, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { value: item.value, label: item.label },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.batchForm.modType == "resultpage"
                ? _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-select",
                        {
                          key: "3",
                          staticStyle: { width: "70%" },
                          attrs: { size: "small", value: "" },
                          model: {
                            value: _vm.batchForm.modValue,
                            callback: function ($$v) {
                              _vm.$set(_vm.batchForm, "modValue", $$v)
                            },
                            expression: "batchForm.modValue",
                          },
                        },
                        _vm._l(_vm.resultPageOptions, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { value: item.value, label: item.label },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.batchForm.modType == "shieldip"
                ? _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "IP屏蔽",
                            prop: "ipid",
                            "label-width": "auto",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              key: "4",
                              staticStyle: { width: "70%" },
                              attrs: { size: "small", value: "" },
                              model: {
                                value: _vm.batchForm.modValue,
                                callback: function ($$v) {
                                  _vm.$set(_vm.batchForm, "modValue", $$v)
                                },
                                expression: "batchForm.modValue",
                              },
                            },
                            _vm._l(_vm.shieldIpOptions, function (item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { value: item.value, label: item.label },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.batchForm.modType == "shieldterminal"
                ? _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "终端屏蔽",
                            prop: "terminal",
                            "label-width": "auto",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              key: "5",
                              staticStyle: { width: "70%" },
                              attrs: { size: "small", value: "", multiple: "" },
                              model: {
                                value: _vm.batchForm.modValue,
                                callback: function ($$v) {
                                  _vm.$set(_vm.batchForm, "modValue", $$v)
                                },
                                expression: "batchForm.modValue",
                              },
                            },
                            _vm._l(_vm.gconfig.terminals, function (item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { value: item.value, label: item.label },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  nativeOn: {
                    click: function ($event) {
                      return _vm.formCancel("batchform")
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.formLoading },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.handleSubmitBatch($event)
                    },
                  },
                },
                [_vm._v("提交")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "nginx配置",
            visible: _vm.nginxVisible,
            "close-on-click-modal": false,
            width: "60%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.nginxVisible = $event
            },
          },
        },
        [
          _c("el-input", {
            attrs: {
              type: "textarea",
              readonly: "true",
              autosize: { minRows: 1, maxRows: 100 },
            },
            model: {
              value: _vm.nginxConf,
              callback: function ($$v) {
                _vm.nginxConf = $$v
              },
              expression: "nginxConf",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "修改/添加",
            visible: _vm.formVisible,
            "close-on-click-modal": false,
            width: "60%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.formVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.formData,
                "label-width": "100px",
                "label-position": "left",
              },
            },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "域名",
                            prop: "domain",
                            required: "",
                            "label-width": "auto",
                          },
                        },
                        [
                          _c("el-input", {
                            staticStyle: { width: "80%" },
                            attrs: {
                              size: "small",
                              disabled: _vm.formType == 2,
                            },
                            model: {
                              value: _vm.formData.domain,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "domain", $$v)
                              },
                              expression: "formData.domain",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 4 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "端口",
                            prop: "port",
                            required: "",
                            "label-width": "auto",
                          },
                        },
                        [
                          _c("el-input", {
                            staticStyle: { width: "50%" },
                            attrs: {
                              size: "small",
                              disabled:
                                _vm.formType == 2 && !_vm.gconfig.isAdmin(),
                            },
                            model: {
                              value: _vm.formData.port,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "port", _vm._n($$v))
                              },
                              expression: "formData.port",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 4 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "平台",
                            prop: "platform",
                            "label-width": "auto",
                            required: "",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "60%" },
                              attrs: {
                                size: "small",
                                value: "",
                                disabled:
                                  _vm.formType == 2 && !_vm.gconfig.isAdmin(),
                                filterable: "",
                              },
                              model: {
                                value: _vm.formData.platform,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formData, "platform", $$v)
                                },
                                expression: "formData.platform",
                              },
                            },
                            _vm._l(_vm.platformOptions, function (item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { value: item.value, label: item.label },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.gconfig.isBaidu(
                    _vm.getPlatformName(_vm.formData.platform)
                  ) ||
                  _vm.gconfig.isUC(
                    _vm.getPlatformName(_vm.formData.platform)
                  ) ||
                  _vm.gconfig.isVivo(_vm.getPlatformName(_vm.formData.platform))
                    ? _c(
                        "div",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 5 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "token",
                                    prop: "app_key",
                                    "label-width": "auto",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    staticStyle: { width: "70%" },
                                    attrs: {
                                      size: "small",
                                      disabled:
                                        _vm.formType == 2 &&
                                        _vm.gconfig.isGuest(),
                                    },
                                    model: {
                                      value: _vm.formData.app_key,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.formData, "app_key", $$v)
                                      },
                                      expression: "formData.app_key",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.gconfig.is360(_vm.getPlatformName(_vm.formData.platform))
                    ? _c(
                        "div",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 5 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "key",
                                    prop: "app_key",
                                    "label-width": "auto",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    staticStyle: { width: "70%" },
                                    attrs: {
                                      size: "small",
                                      disabled:
                                        _vm.formType == 2 &&
                                        _vm.gconfig.isGuest(),
                                    },
                                    model: {
                                      value: _vm.formData.app_key,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.formData, "app_key", $$v)
                                      },
                                      expression: "formData.app_key",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 5 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "secret",
                                    prop: "app_secret",
                                    "label-width": "auto",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    staticStyle: { width: "70%" },
                                    attrs: {
                                      size: "small",
                                      disabled:
                                        _vm.formType == 2 &&
                                        _vm.gconfig.isGuest(),
                                    },
                                    model: {
                                      value: _vm.formData.app_secret,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.formData,
                                          "app_secret",
                                          $$v
                                        )
                                      },
                                      expression: "formData.app_secret",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 5 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "jzqs",
                                    prop: "jzqs",
                                    "label-width": "auto",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    staticStyle: { width: "70%" },
                                    attrs: {
                                      size: "small",
                                      disabled:
                                        _vm.formType == 2 &&
                                        _vm.gconfig.isGuest(),
                                    },
                                    model: {
                                      value: _vm.formData.jzqs,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.formData, "jzqs", $$v)
                                      },
                                      expression: "formData.jzqs",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 5 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "推广账号",
                            prop: "adv_account",
                            "label-width": "auto",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "60%" },
                              attrs: {
                                size: "small",
                                value: "",
                                clearable: "",
                                filterable: "",
                              },
                              model: {
                                value: _vm.formData.adv_account,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formData, "adv_account", $$v)
                                },
                                expression: "formData.adv_account",
                              },
                            },
                            _vm._l(
                              _vm.formatAdvAccount(_vm.formData.platform),
                              function (item) {
                                return _c("el-option", {
                                  key: item.value,
                                  attrs: {
                                    value: item.value,
                                    label: item.label,
                                  },
                                })
                              }
                            ),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "普通页",
                            prop: "normal_page",
                            "label-width": "auto",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "70%" },
                              attrs: {
                                size: "small",
                                value: "",
                                clearable: "",
                                filterable: "",
                              },
                              model: {
                                value: _vm.formData.normal_page,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formData, "normal_page", $$v)
                                },
                                expression: "formData.normal_page",
                              },
                            },
                            _vm._l(_vm.normalPageOptions, function (item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { value: item.value, label: item.label },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "审核页",
                            prop: "examine_page",
                            "label-width": "auto",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "70%" },
                              attrs: {
                                size: "small",
                                value: "",
                                clearable: "",
                                filterable: "",
                              },
                              model: {
                                value: _vm.formData.examine_page,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formData, "examine_page", $$v)
                                },
                                expression: "formData.examine_page",
                              },
                            },
                            _vm._l(_vm.examinePageOptions, function (item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { value: item.value, label: item.label },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "结果页",
                            prop: "result_page_id",
                            "label-width": "auto",
                            required: "",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "70%" },
                              attrs: {
                                size: "small",
                                value: "",
                                filterable: "",
                              },
                              model: {
                                value: _vm.formData.result_page_id,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formData, "result_page_id", $$v)
                                },
                                expression: "formData.result_page_id",
                              },
                            },
                            _vm._l(_vm.resultPageOptions, function (item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { value: item.value, label: item.label },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "公司名字",
                            prop: "company_name",
                            "label-width": "auto",
                          },
                        },
                        [
                          _c("el-input", {
                            staticStyle: { width: "70%" },
                            attrs: { size: "small" },
                            model: {
                              value: _vm.formData.company_name,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "company_name", $$v)
                              },
                              expression: "formData.company_name",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "公司地址",
                            prop: "company_addr",
                            "label-width": "auto",
                          },
                        },
                        [
                          _c("el-input", {
                            staticStyle: { width: "70%" },
                            attrs: { size: "small" },
                            model: {
                              value: _vm.formData.company_addr,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "company_addr", $$v)
                              },
                              expression: "formData.company_addr",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "公司电话",
                            prop: "company_phone",
                            "label-width": "auto",
                          },
                        },
                        [
                          _c("el-input", {
                            staticStyle: { width: "70%" },
                            attrs: { size: "small" },
                            model: {
                              value: _vm.formData.company_phone,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "company_phone", $$v)
                              },
                              expression: "formData.company_phone",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "域名描述",
                            prop: "desc",
                            "label-width": "auto",
                          },
                        },
                        [
                          _c("el-input", {
                            staticStyle: { width: "70%" },
                            attrs: { size: "small" },
                            model: {
                              value: _vm.formData.desc,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "desc", $$v)
                              },
                              expression: "formData.desc",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 5 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "IP屏蔽",
                            prop: "ipid",
                            "label-width": "auto",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "60%" },
                              attrs: {
                                size: "small",
                                value: "",
                                clearable: "",
                                filterable: "",
                              },
                              model: {
                                value: _vm.formData.shield_conf_js.ipid,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.formData.shield_conf_js,
                                    "ipid",
                                    $$v
                                  )
                                },
                                expression: "formData.shield_conf_js.ipid",
                              },
                            },
                            _vm._l(_vm.shieldIpOptions, function (item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { value: item.value, label: item.label },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 7 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "终端屏蔽",
                            prop: "terminal",
                            "label-width": "auto",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "70%" },
                              attrs: {
                                size: "small",
                                value: "",
                                multiple: "",
                                clearable: "",
                                filterable: "",
                              },
                              model: {
                                value: _vm.formData.shield_conf_js.terminal,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.formData.shield_conf_js,
                                    "terminal",
                                    $$v
                                  )
                                },
                                expression: "formData.shield_conf_js.terminal",
                              },
                            },
                            _vm._l(_vm.gconfig.terminals, function (item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { value: item.value, label: item.label },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "城市屏蔽",
                            prop: "city",
                            "label-width": "auto",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "70%" },
                              attrs: {
                                size: "small",
                                value: "",
                                multiple: "",
                                clearable: "",
                                filterable: "",
                              },
                              model: {
                                value: _vm.formData.shield_conf_js.city,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.formData.shield_conf_js,
                                    "city",
                                    $$v
                                  )
                                },
                                expression: "formData.shield_conf_js.city",
                              },
                            },
                            _vm._l(
                              _vm.gconfig.getCityOptions(),
                              function (item) {
                                return _c("el-option", {
                                  key: item.value,
                                  attrs: {
                                    value: item.value,
                                    label: item.label,
                                  },
                                })
                              }
                            ),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              !_vm.gconfig.isUC(_vm.getPlatformName(_vm.formData.platform))
                ? _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "全部表单回传比例",
                                prop: "terminal",
                                "label-width": "auto",
                              },
                            },
                            [
                              _c("el-input", {
                                staticStyle: { width: "20%" },
                                attrs: { size: "small" },
                                model: {
                                  value: _vm.formData.spec_form_feedback_rate,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.formData,
                                      "spec_form_feedback_rate",
                                      _vm._n($$v)
                                    )
                                  },
                                  expression:
                                    "formData.spec_form_feedback_rate",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "动态脚本",
                            prop: "terminal",
                            "label-width": "auto",
                          },
                        },
                        [
                          _c("el-input", {
                            staticStyle: { width: "80%" },
                            attrs: {
                              type: "textarea",
                              autosize: { minRows: 1, maxRows: 100 },
                            },
                            model: {
                              value: _vm.formData.dyn_script,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "dyn_script", $$v)
                              },
                              expression: "formData.dyn_script",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  nativeOn: {
                    click: function ($event) {
                      return _vm.formCancel("form")
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.formLoading },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.handleSubmit($event)
                    },
                  },
                },
                [_vm._v("提交")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }