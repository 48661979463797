import login from './views/login.vue'
import home from './views/home.vue'
import accountmgr from './views/accountmgr/accountmgr.vue'
import blockphone from './views/accountmgr/blockphone.vue'
import smsconfig from './views/accountmgr/smsconfig.vue'
import platform from './views/accountmgr/platform.vue'
import apiconfig from './views/accountmgr/apiconfig.vue'

import domainmgr from './views/domainmgr/domainmgr.vue'
import shieldip from './views/domainmgr/shieldip.vue'
import resultpage from './views/domainmgr/resultpage.vue'
import advaccount from './views/domainmgr/advaccount.vue'
import advturnover from './views/domainmgr/advturnover.vue'
import advmanager from './views/domainmgr/advmanager.vue'
import whiteiplist from './views/domainmgr/whiteiplist.vue'

import pagemgr from './views/pagemgr/pagemgr.vue'

import datareport from './views/datareport/linereport.vue'
import staffreport from './views/datareport/staffreport.vue'
import guestreport from './views/datareport/guestreport.vue'

import ordermgr from './views/ordermgr/ordermgr.vue'
import dealorders from './views/ordermgr/dealorders.vue'

let routes = [
    {
        path: '/login',
        component: login,
        name: '',
        hidden: true
    },
    {
        path: '/',
        component: home,
        hidden: true,
        children: [],
    }
];
let routeSize = routes.length;

let menus = [
    {name: 'home', component: home},
    {name: 'accountmgr', component: accountmgr},
    {name: 'blockphone', component: blockphone},
    {name: 'smsconfig', component: smsconfig},
    {name: 'platform', component: platform},
    {name: 'apiconfig', component: apiconfig},
    {name: 'domainmgr', component: domainmgr},
    {name: 'shieldip', component: shieldip},
    {name: 'resultpage', component: resultpage},
    {name: 'advaccount', component: advaccount},
    {name: 'advturnover', component: advturnover},
    {name: 'pagemgr', component: pagemgr},
    {name: 'ordermgr', component: ordermgr},
    {name: 'dealorders', component: dealorders},
    {name: 'datareport', component: datareport},
    {name: 'staffreport', component: staffreport},
    {name: 'guestreport', component: guestreport},
    {name: 'advmanager', component: advmanager},
    {name: 'whiteiplist', component: whiteiplist},
];

export {routes, routeSize, menus};
