var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-row",
    { staticClass: "container" },
    [
      _c(
        "el-col",
        { staticClass: "header", attrs: { span: 24 } },
        [
          _c(
            "el-col",
            {
              staticClass: "logo",
              class: _vm.collapsed ? "logo-collapse-width" : "logo-width",
              attrs: { span: 10 },
            },
            [
              _vm._v(
                "\n      " + _vm._s(_vm.collapsed ? "" : _vm.sysName) + "\n    "
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "el-col",
            {
              staticStyle: {
                "margin-left": "10px",
                color: "green",
                "font-weight": "bold",
              },
              attrs: { span: 8 },
            },
            [_vm._v("\n      " + _vm._s(_vm.getCurrentPage()) + "\n    ")]
          ),
          _vm._v(" "),
          _c(
            "el-col",
            { staticClass: "userinfo", attrs: { span: 4 } },
            [
              _c(
                "el-dropdown",
                { attrs: { trigger: "hover" } },
                [
                  _c(
                    "span",
                    {
                      staticClass: "el-dropdown-link userinfo-inner",
                      staticStyle: { "font-weight": "bold" },
                    },
                    [_vm._v(_vm._s(_vm.sysUserName))]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-dropdown-menu",
                    { attrs: { slot: "dropdown" }, slot: "dropdown" },
                    [
                      _c(
                        "el-dropdown-item",
                        {
                          nativeOn: {
                            click: function ($event) {
                              return _vm.handleLogout($event)
                            },
                          },
                        },
                        [_vm._v("退出登录")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-col",
        { staticClass: "main", attrs: { span: 24 } },
        [
          _c(
            "el-aside",
            {
              staticClass: "app-aside",
              class: _vm.collapsed ? "menu-collapsed" : "menu-expanded",
            },
            [
              _c(
                "el-menu",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.collapsed,
                      expression: "!collapsed",
                    },
                  ],
                  staticClass: "el-menu-vertical-demo",
                  attrs: {
                    "default-active": _vm.$route.path,
                    "background-color": _vm.bg_color,
                    "unique-opened": "",
                    router: "",
                  },
                  on: {
                    open: _vm.handleopen,
                    close: _vm.handleclose,
                    select: _vm.handleselect,
                  },
                },
                [
                  _vm._l(
                    _vm.routeFilter(_vm.$router.options.routes),
                    function (item, index) {
                      return [
                        !item.leaf
                          ? _c(
                              "el-submenu",
                              { key: index, attrs: { index: index + "" } },
                              [
                                _c("template", { slot: "title" }, [
                                  _c("i", { class: item.iconCls }),
                                  _vm._v(_vm._s(item.name) + "\n            "),
                                ]),
                                _vm._v(" "),
                                _vm._l(
                                  _vm.routeFilter(item.children),
                                  function (child) {
                                    return _c(
                                      "el-menu-item",
                                      {
                                        key: child.path,
                                        staticClass: "menu-list",
                                        attrs: {
                                          index:
                                            child.path +
                                            "?route_id=" +
                                            child.id,
                                        },
                                      },
                                      [
                                        _c("i", { class: item.iconCls }),
                                        _vm._v(
                                          "\n              " +
                                            _vm._s(child.name) +
                                            "\n            "
                                        ),
                                      ]
                                    )
                                  }
                                ),
                              ],
                              2
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        item.leaf && item.children.length > 0
                          ? _c(
                              "el-menu-item",
                              {
                                key: index,
                                attrs: { index: item.children[0].path },
                              },
                              [
                                _c("i", { class: item.iconCls }),
                                _vm._v(
                                  _vm._s(item.children[0].name) + "\n          "
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]
                    }
                  ),
                ],
                2
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("section", { staticClass: "content-container" }, [
            _c(
              "div",
              { staticClass: "grid-content bg-purple-light" },
              [
                _c(
                  "el-col",
                  { staticClass: "content-wrapper", attrs: { span: 24 } },
                  [_c("router-view")],
                  1
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }