<template>
    <section>
        <!-- 选择栏 -->
        <el-col :span="24" class="toolbar" style="padding-bottom: 0; padding-left: 5px;">
            <el-form :inline="true" :model="filters" size="small">
                <el-form-item label="客户账号" prop="guestAccountId" >
                    <el-select size="small" v-model.number="filters.guestAccountId" value="" style="width:140px" filterable clearable>
                        <el-option v-for="item in guestAccountOptions" :key="item.value" :value="item.value" :label="item.label"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="日期" >
                    <el-date-picker size="small" v-model="filters.timeRange" type="daterange" value-format="timestamp"
                        range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" style="width:250px">
                    </el-date-picker>
                </el-form-item>
                <el-form-item >
                    <el-button type="primary" size="small" v-on:click="handleList()" style="width:50px">查询</el-button>
                </el-form-item>
            </el-form>
        </el-col>

        <!-- 列表 -->
        <template>
            <el-table :data="dataList" stripe highlight-current-row v-loading="dataLoading" size="small" height="800"
                style="width: 100%;">
                <!-- <el-table-column prop="date" label="日期" width="100"></el-table-column> -->
                <el-table-column prop="guest_account" label="客户账号" width="100">
                    <template slot-scope="scope">
                        <span style="color:green; font-weight: bold;">{{ scope.row.guest_account }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="adv_cost_total" label="消耗金额" width="100" sortable :sort-method="(a,b)=>{return a.adv_cost_total-b.adv_cost_total}"></el-table-column>
                <el-table-column prop="income_line" label="进线数量" width="100" sortable :sort-method="(a,b)=>{return a.income_line-b.income_line}"></el-table-column>
                <el-table-column prop="income_price" label="进线成本" width="100" sortable :sort-method="(a,b)=>{return a.income_price-b.income_price}"></el-table-column>
                <el-table-column prop="cash_cost" label="现金消耗" width="100" sortable :sort-method="(a,b)=>{return a.cash_cost-b.cash_cost}"></el-table-column>
                <el-table-column prop="cash_price" label="现金成本" width="100" sortable :sort-method="(a,b)=>{return a.cash_price-b.cash_price}"></el-table-column>
                <el-table-column prop="deal_num" label="成交数量" width="100" sortable :sort-method="(a,b)=>{return a.deal_num-b.deal_num}"></el-table-column>
                <el-table-column prop="deal_rate" label="成交率" width="100" sortable :sort-method="(a,b)=>{return a.deal_rate-b.deal_rate}">
                    <template slot-scope="scope">
                        {{ scope.row.deal_rate }}%
                    </template>
                </el-table-column>
                <el-table-column prop="check_std" label="考核标准" width="100" sortable :sort-method="(a,b)=>{return a.check_std-b.check_std}"></el-table-column>
            </el-table>

            <!-- 翻页 -->
            <el-col :span="24" class="toolbar">
                <el-pagination layout="total, sizes, prev, pager, next" :current-page="this.page"
                    :total="total"
                    :page-size="pageSize" 
                    @current-change="handleCurrentChange" 
                    @size-change="handleSizeChange"
                    :page-sizes="[100,200,400]"
                    style="float:right;">
                </el-pagination>
            </el-col> 
        </template>
    </section>
</template>

<script>
import util from "../../common/js/util";
import gconfig from "../../api/gconfig";
import model from "../../common/js/model";
import { postRequest, formatTimestamp, getDotTime} from "../../api/api";

export default {
    data() {
        return {
            filters: {
                guestAccountId: "",
                timeRange: [getDotTime() * 1000, getDotTime() * 1000],
            },

         
            total: 0,
            page: 1,
            pageSize: 100,
            dataList: [],
            dataLoading: false,

            guestAccountOptions: [],
            gconfig: gconfig,
        }
    },

    methods: {
        formatTime(row, column, cellValue) {
            if (cellValue == 0) {
                return "0000-00-00 00:00:00"
            }
            return formatTimestamp(cellValue);
        },
        formatPlatform(row, column, cellValue) {
            for (let item of this.platformOptions) {
                if (item.value == cellValue) {
                    return item.label;
                }
            }
            return cellValue;
        },

        handleList() {
            let params = Object.assign({}, this.filters);
            params.page = this.page,
            params.pageSize = this.pageSize,

            this.dataLoading = true;
            let queryModel = model.GetQueryModel("DataReportCtrl", "GetGuestReport", params);
            postRequest(queryModel).then(res => {
                this.dataLoading = false;

                if (res.data.msg !== "") {
                    this.$message.warning(res.data.msg);
                    return;
                }
                if (this.page === 1) {
                    this.total = res.data.total;
                }

                this.dataList = res.data.datas;
                let sum = {
                    "staff_account": "-", 
                    "adv_account": "-", 
                    "guest_account": "合计", 
                    "date": "-", 
                    "platform": "-", 
                    "domain": "-", 
                    "adv_info_cost": 0, 
                    "adv_search_cost": 0, 
                    "adv_info_balance": 0, 
                    "adv_search_balance": 0,
                    "income_line": 0, 
                    "deal_num": 0, 
                    "adv_cost_total": 0, 
                    "adv_balance_total": 0, 
                    "income_price": "0.00", 
                    "cash_cost": 0,
                    "cash_price": "0.00",
                    "deal_rate": "0.00",
                    "check_std": "0.00"
                }
                for(let val of this.dataList) {
                    sum.income_line += val.income_line;
                    sum.deal_num += val.deal_num;
                    sum.adv_cost_total += val.adv_cost_total;
                    sum.adv_balance_total += val.adv_balance_total;
                    //现金消耗
                    sum.cash_cost += val.cash_cost;

                    val.adv_cost_total = val.adv_cost_total.toFixed(2);
                    val.adv_balance_total = val.adv_balance_total.toFixed(2);
                    val.cash_cost = val.cash_cost.toFixed(2);
                }
                //进线成本
                let income_price = sum.adv_cost_total / Math.max(1, sum.income_line)
                //成交率
                let deal_rate = sum.deal_num / Math.max(1, sum.income_line);

                //现金成本
                let cash_price = sum.cash_cost / Math.max(1, sum.income_line);
                sum.cash_price = cash_price.toFixed(2);

                sum.check_std = (deal_rate / Math.max(1, cash_price) * 10000).toFixed(2);

                sum.income_price = income_price.toFixed(2);
                sum.deal_rate = (deal_rate * 100).toFixed(2);
                sum.cash_cost = sum.cash_cost.toFixed(2);
                sum.adv_cost_total = sum.adv_cost_total.toFixed(2);
                sum.adv_balance_total = sum.adv_balance_total.toFixed(2);
                this.dataList.push(sum);

                console.log("handleList", this.dataList);

            });
        },

        handleCurrentChange(page) {
            this.page = page;
            this.handleList();
        },

        handleSizeChange(val) {
            this.pageSize = val;
            this.handleList();
        },

        handleGuestAccountList() {
            let params = {};
            let queryModel = model.GetQueryModel("AccountMgrCtrl", "GetGuestAccountList", params);
            postRequest(queryModel).then(res => {
                if (res.data.msg !== "") {
                    this.$message.warning(res.data.msg);
                    return;
                }
                for (let val of res.data.datas) {
                    this.guestAccountOptions.push({label:val.name, value:val.id});
                }
                console.log("handleGuestAccountList", this.guestAccountOptions);

            });
        },
    },
    mounted() {
        this.handleGuestAccountList();
        this.handleList();
    }
}
</script>
<style scoped>
</style>