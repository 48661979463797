import model from "@/common/js/model";
import util from "@/common/js/util";
import { postRequest } from "@/api/api";

//test
export const increment = ({commit}) => {
    commit('INCREMENT')
}
export const decrement = ({commit}) => {
    commit('DECREMENT')
}

export const getDeeplinkConfig = ({commit}) => {
    return new Promise((resolve, reject) => {
      const para = {
            page: 0,
            page_size: 0,
            flag: 1,
            value: "20"
        }
        var queryModel = model.GetQueryModel("CommonDataCtrl", "GetCommonData", para);
        postRequest(queryModel).then((res) => {
          if (!util.isEmpty(res.data.msg)) {
            this.$message({
              message: res.data.msg,
              type: "warning",
            });
          } else {
            commit('SET_DEEPLINK_CONFIG', res.data.datas)
            commit('SET_HAS_DEEPLINK_CONFIG', true)
          }
          resolve(res.data)
        }).catch(err => {
            reject(err)
        });
    })
}