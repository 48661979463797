
export const filterRuleType = {
  PERCENT: 'PERCENT',
  DECIMAL: 'DECIMAL',
  CUSTOM: 'CUSTOM',
  ORDER: 'ORDER',
  TIME: 'TIME',
  SELECTION: 'SELECTION',
  IMAGE: 'IMAGE',
}

export function formatJSON(filterRule, jsonData) {
  return jsonData.map((row, rowIndex) => filterRule.map(ruler => {
    const RULER_TYPE = (typeof ruler).toLowerCase()
    if (RULER_TYPE === 'string') {
      return row[ruler]
    }
    let key = ruler.filterVal
    let val = row[key]
    let fn = ruler.fn
    switch (ruler.convert) {
      case 'PERCENT':
        return toPercent(val, fn)
      case 'DECIMAL':
        return toFixed(val, fn)
      case 'CUSTOM':
        return toCustomData(val, fn, row)
      case 'ORDER':
        return rowIndex + 1
      case 'TIME':
        return toTime(val, fn)
      case 'SELECTION':
        return toSelected(val, fn)
      default:
        return val
    }

  }))
}

export function mapGenerator(data, keyName, valName, perfixName = '') {
  let result = {}
  let key
  data.forEach(item => {
      key = '' + perfixName + item[keyName]
      result[key] = item[valName]
  })
  console.log(result)
  return result
}

function toFixed(val, fn) {
  if (fn) {
    return fn(val)
  }
  return Number(val).toFixed(2)
}

function toPercent(val, fn) {
  if (fn) {
    return fn(val)
  }
  return val + '%'
}

function toCustomData(val, fn, row) {
  if (typeof fn === 'function') {
    return fn(row)
  }
  return val
}

function toTime(val, fn) {
  if (typeof fn === 'function') {
    return fn(val)
  }
  return val
}

function toSelected(val, fn) {
  return fn(val) || 'unknow'
}
