<template>
  <el-row class="container">
    <el-col :span="24" class="header">
      <el-col :span="10" class="logo" :class="collapsed ? 'logo-collapse-width' : 'logo-width'">
        {{ collapsed ? "" : sysName }}
      </el-col>

      <el-col :span="8" style="margin-left:10px;color:green; font-weight: bold;">
        {{ getCurrentPage() }}
      </el-col>

      <el-col :span="4" class="userinfo">
        <el-dropdown trigger="hover">
          <span class="el-dropdown-link userinfo-inner" style="font-weight: bold;">{{ sysUserName }}</span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item @click.native="handleLogout">退出登录</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </el-col>
    </el-col>

    <el-col :span="24" class="main">
      <el-aside class="app-aside" :class="collapsed ? 'menu-collapsed' : 'menu-expanded'">
        <el-menu :default-active="$route.path" class="el-menu-vertical-demo" :background-color="bg_color"
          @open="handleopen" @close="handleclose" @select="handleselect" unique-opened router v-show="!collapsed">
          <template v-for="(item, index) in routeFilter($router.options.routes)">
            <el-submenu :index="index + ''" v-if="!item.leaf" :key="index">
              <template slot="title">
                <i :class="item.iconCls"></i>{{ item.name }}
              </template>
              <el-menu-item class="menu-list" v-for="child in routeFilter(item.children)"
                :index="child.path + '?route_id=' + child.id" :key="child.path">
                <i :class="item.iconCls"></i>
                {{ child.name }}
              </el-menu-item>
            </el-submenu>
            <el-menu-item :key="index" v-if="item.leaf && item.children.length > 0" :index="item.children[0].path"><i
                :class="item.iconCls"></i>{{ item.children[0].name }}
            </el-menu-item>
          </template>
        </el-menu>
      </el-aside>
      <section class="content-container">
        <div class="grid-content bg-purple-light">
          <el-col :span="24" class="content-wrapper">
            <router-view></router-view>
          </el-col>
        </div>
      </section>
    </el-col>
  </el-row>
</template>

<script>
import { getBgColor, postRequest, initRoute } from "../api/api";
import model from "../common/js/model";
import to from "await-to-js";

export default {
  components: {
  },
  data() {
    return {
      sysName: "订单管理后台",
      collapsed: false,
      sysUserName: "",
      sysUserAvatar: "",
      bg_color: getBgColor(),
      currentPage: "",
    };
  },
  methods: {
    routeFilter(routeList) {
      let newList = [];
      for (let v of routeList) {
        if (v.hidden) {
          continue;
        }
        newList.push(v);
      }
      return newList;
    },

    getCurrentPage() {
      return this.$route.name;
    },

    handleopen() {
    },
    handleclose() {
    },

    handleselect: function (a, b) {
      var values = a.split("?");
      if (values.length == 2) {
        var temp = values[1].split("=");
        localStorage.setItem("ROUTE_ID", temp[1]);
      }
    },
    //退出登录
    handleLogout: function () {
      let self = this;
      this.$confirm("确认退出吗?", "提示", {}).then(() => {
        let md = model.GetQueryModel(
          "AccountMgrCtrl",
          "AdminLogout",
          {}
        );
        postRequest(md).then(res => {
          if (res.data.msg) {
            this.$message.warning(err);
            return
          }
          self.$router.push("/login");
        }).catch((err)=>{
            this.$message.warning(err);
        });
      });
    },

    //折叠导航栏
    collapse: function () {
      this.collapsed = this.collapsed;
    },
    showMenu(i, status) {
      this.$refs.menuCollapsed.getElementsByClassName(
        "submenu-hook-" + i
      )[0].style.display = status ? "block" : "none";
    },

    async getRouteInfo() {
      const routeInfoQueryModel = model.GetQueryModel(
        "AccountMgrCtrl",
        "AdminGetRouteInfo",
        {}
      );
      let [err, res] = await to(postRequest(routeInfoQueryModel));
      if (err) {
        console.log(err);
        return Promise.reject(err);
      }
      var routerJson = res.data.datas;
      localStorage.setItem("ROUTER_DATA", JSON.stringify(routerJson));
      console.log("getRouteInfo###", routerJson);
      return res;
    },
  },
  mounted() {
    var user = localStorage.getItem("USER");
    if (user) {
      user = JSON.parse(user);
      this.sysUserName = user.name || "";
    }
    console.log("mount########")
  },

  created() {
    this.getRouteInfo();
  },
};
</script>

<style scoped lang="scss">
@import "@/styles/vars.scss";

$aside-width: 230px;
$header-height: 40px;

.container {
  background: $color-primary;

  .header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: $header-height;
    line-height: $header-height;
    color: #fff;
    z-index: 1002;
    background: rgb(193, 205, 193);

    .userinfo {
      text-align: right;
      padding-right: 35px;
      float: right;

      .userinfo-inner {
        cursor: pointer;
        color: green;

        img {
          width: $header-height;
          height: $header-height;
          border-radius: 20px;
          margin: 10px 0px 10px 10px;
          float: right;
        }
      }
    }

    .logo {
      height: $header-height;
      font-size: 25px;
      color: #5EFFD7;
      font-weight: bold;
      padding-left: 30px;
      padding-right: 20px;
      background-color: #BABBB7;
      border-color: rgba(238, 241, 146, 0.3);
      border-right-width: 1px;
      border-right-style: solid;

      img {
        width: $header-height;
        float: left;
        margin: 10px 10px 10px 18px;
      }

      .txt {
        color: #fff;
      }
    }

    .logo-width {
      width: 230px;
    }

    .logo-collapse-width {
      width: 60px;
    }

    .tools {
      padding: 0px 23px;
      width: 14px;
      height: $header-height;
      line-height: $header-height;
      cursor: pointer;
    }
  }

  .main {
    .app-aside {
      position: fixed;
      top: $header-height;
      bottom: 0;
      width: $aside-width !important;

      .el-menu {
        height: 100%;
      }

      .collapsed {
        width: 60px;

        .item {
          position: relative;
        }

        .submenu {
          position: absolute;
          top: 0px;
          left: 60px;
          z-index: 99999;
          height: auto;
          display: none;
        }
      }
    }

    .menu-collapsed {
      flex: 0 0 60px;
      width: 60px;
    }

    .menu-expanded {
      width: $aside-width;
    }

    .content-container {
      // min-height: 100vh;
      height: auto;
      padding-left: $aside-width;
      padding-top: $header-height;

      .breadcrumb-container {
        .title {
          width: 200px;
          float: left;
          color: #475669;
        }

        .breadcrumb-inner {
          float: right;
        }
      }

      .content-wrapper {
        background-color: #c1cdc1;
        box-sizing: border-box;
      }
    }
  }

}

.el-submenu__title {
  height: 40px;
  line-height: 40px;
}

.el-submenu .el-menu-item {
  height: 35px;
  line-height: 35px;
}
</style>
