<template>
  <section>
    <!-- 选择栏 -->
    <el-col
      :span="24"
      class="toolbar"
      style="padding-bottom: 0; padding-left: 5px"
    >
      <el-form :inline="true" :model="filters" ref="filtersform">
        <el-row>
          <el-form-item label="平台" prop="platformId">
            <el-select
              size="small"
              v-model="filters.platformId"
              value=""
              style="width: 140px"
              filterable
              clearable
            >
              <el-option
                v-for="item in platformOptions"
                :key="item.value"
                :value="item.value"
                :label="item.label"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" size="small" v-on:click="handleList()"
              >查询</el-button
            >
            <el-button
              size="small"
              v-on:click="handleAdd()"
              >增加</el-button
            >
          </el-form-item>
        </el-row>
      </el-form>
    </el-col>

    <!-- 列表 -->
    <template>
      <el-table
        :data="dataList"
        stripe
        highlight-current-row
        v-loading="dataLoading"
        size="small"
        height="800"
        style="width: 100%"
      >
      <el-table-column
          prop="id"
          label="id"
          width="100"
        ></el-table-column>
        <el-table-column
          prop="platform"
          label="平台"
          width="100"
          :formatter="formatPlatform">
        </el-table-column>
        <el-table-column
          prop="open_id"
          label="openId"
          width="150"
          :show-overflow-tooltip="true"
        ></el-table-column>
        <el-table-column
          prop="user_id"
          label="userId"
          width="100"
        ></el-table-column>
        <el-table-column
          prop="user_name"
          label="userName"
          width="130"
        ></el-table-column>
        <el-table-column
          prop="app_id"
          label="appId"
          width="150"
          :show-overflow-tooltip="true"
        ></el-table-column>
        <el-table-column
          prop="secret_key"
          label="secretKey"
          width="150"
          :show-overflow-tooltip="true"
        ></el-table-column>
        <el-table-column
          prop="access_token"
          label="accessToken"
          width="150"
          :show-overflow-tooltip="true"
        ></el-table-column>
        <el-table-column
          prop="refresh_token"
          label="refreshToken"
          width="150"
          :show-overflow-tooltip="true"
        ></el-table-column>


        <el-table-column label="操作" min-width="200" fixed="right">
          <template slot-scope="scope">
            <el-button
              v-if="!gconfig.isGuest()"
              size="small"
              @click="handleEdit(scope.row)"
              style="width: 50px; margin-left: 3px"
              >修改</el-button
            >
            <el-button
              v-if="!gconfig.isGuest()"
              type="danger"
              size="small"
              @click="handleDelete(scope.row)"
              style="width: 50px; margin-left: 3px"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <!-- 翻页 -->
      <el-col :span="24" class="toolbar">
        <el-pagination
          layout="total, sizes, prev, pager, next"
          :current-page="this.page"
          :total="total"
          :page-size="pageSize"
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
          :page-sizes="[100, 200, 400]"
          style="float: right"
        >
        </el-pagination>
      </el-col>
    </template>

    <!-- 添加/修改 -->
    <el-dialog
      title="添加/修改"
      :visible.sync="formVisible"
      :close-on-click-modal="false"
      width="60%"
    >
      <el-form
        :model="formData"
        label-width="100px"
        ref="addform"
        label-position="left"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item label="平台" prop="platform">
              <el-select
                size="small"
                v-model.number="formData.platform"
                value=""
                style="width: 80%"
                filterable
                clearable
              >
                <el-option
                  v-for="item in platformOptions"
                  :key="item.value"
                  :value="item.value"
                  :label="item.label"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="openId" prop="open_id">
              <el-input
                style="width: 80%"
                size="small"
                v-model="formData.open_id"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="userId" prop="user_id">
              <el-input
                style="width: 80%"
                size="small"
                v-model.number="formData.user_id"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="userName" prop="user_name">
              <el-input
                style="width: 80%"
                size="small"
                v-model="formData.user_name"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="appId" prop="app_id">
              <el-input
                style="width: 80%"
                size="small"
                v-model="formData.app_id"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="secretKey" prop="secret_key">
              <el-input
                style="width: 80%"
                size="small"
                v-model="formData.secret_key"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row> 
        <el-row>
          <el-col :span="12">
            <el-form-item label="accessToken" prop="access_token">
              <el-input
                style="width: 80%"
                size="small"
                v-model="formData.access_token"
              ></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="refreshToken" prop="refresh_token">
              <el-input
                style="width: 80%"
                size="small"
                v-model="formData.refresh_token"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click.native="handleAddCancel">取消</el-button>
        <el-button
          type="primary"
          @click.native="handleAddSubmit"
          :loading="formLoading"
          >提交</el-button
        >
      </div>
    </el-dialog>
  </section>
</template>

<script>
import util from "../../common/js/util";
import gconfig from "../../api/gconfig";
import model from "../../common/js/model";
import { postRequest, formatTimestamp, getDotTime } from "../../api/api";

export default {
  data() {
    return {
      filters: {
        platformId: "",
      },
      page: 1,
      pageSize: 100,
      total: 1,
      dataList: [],
      dataLoading: false,
      formData: {
        id:0,
        platform:0,
        app_id:0,
        secret_key:"",
        user_id:0,
        user_name:"",
        access_token:"",
        refresh_token:"",
        open_id:"",
        create_time:0
      },
      formVisible: false,
      formLoading: false,
      optype:1, //1添加;2修改

      gconfig: gconfig,
      platformOptions: [],
    };
  },

  methods: {
    formatTime(row, column, cellValue) {
      if (cellValue == 0) {
        return "0000-00-00 00:00:00";
      }
      return formatTimestamp(cellValue);
    },

    formatPlatform(row, column, cellValue) {
      for (let item of this.platformOptions) {
        if (item.value == cellValue) {
          return item.label;
        }
      }
      if (cellValue == 0) {
        return "未设置:" + cellValue;
      }
      return "未知:" + cellValue;
    },

    handleList() {
      this.$refs.filtersform.validate((valid) => {
        if (valid) {
          let params = {
            page: this.page,
            pageSize: this.pageSize,
            platformId: this.filters.platformId,
          };
          this.dataLoading = true;
          let queryModel = model.GetQueryModel(
            "AdvAccountCtrl",
            "GetAdvManagerList",
            params
          );
          postRequest(queryModel).then((res) => {
            this.dataLoading = false;

            if (res.data.msg !== "") {
              this.$message.warning(res.data.msg);
              return;
            }
            if (this.page === 1) {
              this.total = res.data.total;
            }
            this.dataList = res.data.datas;
            console.log("GetList", this.dataList);
          });
        }
      });
    },

    handleCurrentChange(page) {
      this.page = page;
      this.handleList();
    },

    handleSizeChange(val) {
      this.pageSize = val;
      this.handleList();
    },

    handleAdd() {
      this.optype = 1;
      this.formVisible = true;
      this.formLoading = false;
    },

    handleEdit(row) {
      this.optype = 2;
      this.formData = Object.assign({}, row);
      this.formVisible = true;
      this.formLoading = false;
    },

    getDomainPlatform(domain) {
      for (let item of this.platformOptions) {
        if (item.value == domain) {
          return item.platform;
        }
      }
      return 0;
    },

    handleAddSubmit() {
      this.$refs.addform.validate((valid) => {
        if (valid) {
          this.formLoading = true;
          let params = Object.assign({}, this.formData);
          let queryModel = model.GetQueryModel(
            "AdvAccountCtrl",
            "UpdateAdvManager",
            params
          );
          postRequest(queryModel).then((res) => {
            this.formLoading = false;
            if (res.data.msg !== "") {
              this.$message.warning(res.data.msg);
              return;
            }
            this.formVisible = false;
            this.handleList()
            this.$message.success("操作成功");
          });
        }
      });
    },
    handleAddCancel() {
      this.formVisible = false;
      this.formLoading = false;
      this.$refs.addform.resetFields();
    },
    handleDelete(row) {
      this.$confirm("确认删除吗？", "提示", {}).then(() => {
        this.dataLoading = true;
        let params = {id: row["id"]};
        let queryModel = model.GetQueryModel(
          "AdvAccountCtrl",
          "DelAdvManager",
          params
        );
        postRequest(queryModel).then((res) => {
          this.dataLoading = false;
          if (res.data.msg !== "") {
            this.$message.warning(res.data.msg);
            return;
          }
          this.$message.success("删除成功");
          this.handleList();
        });
      });
    },
  },
  mounted() {
    this.dataLoading = true;
    util.getPlatformOptions(this).then((res) => {
      this.dataLoading = false;
      this.platformOptions = res;
      this.handleList();
    });
  },
};
</script>
<style scoped>
</style>