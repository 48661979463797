import { routes, routeSize, menus} from "@/routes";

export const genNewMenu = (router, rounterDatas) => {
  if (rounterDatas.length === 0) {
    return;
  }

  // 获取的是整个程序的路由, 取第一个节点
  let routeData = rounterDatas[0];
  if (routeData.children.length === 0) {
    return;
  }
  const children = routeData.children;
  let childs = formatRoutes(children);
  let unfound = { path: "*", redirect: "/404", hidden: true }; // 最后添加
  childs.push(unfound);
  
  router.addRoutes(childs);
  childs.forEach((child) => {
    routes.push(child);
  });
};

export const formatRoutes = (aMenu) => {
  const aRouter = [];
  aMenu.forEach((oMenu) => {
    const { id, path, component, name, icon, children, hidden } = oMenu;
    if (validatenull(component.trim())) return;
    const oRouter = {
      id: id,
      path: path,
      component: findMenu(component),
      name: name,
      iconCls: icon,
      hidden: hidden,
      children: validatenull(children) ? [] : formatRoutes(children),
    };
    aRouter.push(oRouter);
  });
  return aRouter;
};

export const findMenu = (name) => {
  var item = menus.find((item) => item.name == name);
  if (item === undefined) {
    return null;
  }
  return item.component;
};

export const validatenull = (value) => {
  if (value == null || value.length <= 0) {
    return true;
  } else {
    return false;
  }
};


