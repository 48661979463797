<template>
  <el-form ref="loginForm" label-position="left" label-width="0px" class="demo-ruleForm login-container" :model="datas">
    <h3 class="title">系统登录</h3>
    <el-form-item label="账号：" label-width="auto" required prop="account">
      <el-input v-model="datas.account" size="small" style="width: 80%;" maxlength="24" minlength="1" />
    </el-form-item>
    <el-form-item label="密码：" label-width="auto" required prop="passwd">
      <el-input v-model="datas.passwd" type="password" size="small" style="width: 80%" maxlength="24" minlength="1"
        show-password />
    </el-form-item>
    <el-form-item style="width: 100%">
      <el-button v-loading="loading" type="primary" size="small" style="width: 100%"
        @click.native.prevent="handleLogin">登录</el-button>
    </el-form-item>
  </el-form>
</template>

<script>
import util from "../common/js/util";
import model from "../common/js/model";
import { getUser, postRequest } from "../api/api";
import qs from "querystring";
import to from "await-to-js";

export default {
  data() {
    return {
      loading: false,

      datas: {
        account: "",
        passwd: "",
      },

      formRules: {},
    };
  },
  methods: {
    async checkLogin(params) {
      this.loading = true
      this.checkLogon(params)
        .then((res) => {
          console.log("checklogon###", res)
          return this.getUserInfo();

        })
        .then((res) => {
          console.log("getUserInfo###", res)
          return this.getRouteInfo();

        })
        .then((res) => {
          console.log("getRouterInfo###", res, location.pathname)
          location.href = location.pathname;

        })
        .catch((err) => {
          console.error("checkLogin err", err);

        }).finally(() => {
          this.loading = false

        });
    },

    // 检测登陆状态
    async checkLogon(params) {
      let user = getUser();
      if (!user) {
        return Promise.reject("user is empty, not login");
      }
      console.log("checkLogin user", user);

      let md = model.GetQueryModel(
        "AccountMgrCtrl",
        "AdminCheckLogin",
        params
      );
      let [err, res] = await to(postRequest(md));
      if (err) {
        return Promise.reject(err);
      }
      if (res.data.msg) {
        return Promise.reject(res.data.msg);
      }
      localStorage.setItem("ACCESS_TOKEN", res.data.datas.access_token);
      return res;
    },

    // 获取用户信息
    async getUserInfo() {
      let md = model.GetQueryModel(
        "AccountMgrCtrl",
        "AdminGetUserInfo",
        {}
      );
      let [err, res] = await to(postRequest(md));
      if (err) {
        return Promise.reject(err);
      }
      if (res.data.msg) {
        return Promise.reject(res.data.msg);
      }
      let user = JSON.parse(JSON.stringify(res.data.datas));
      localStorage.setItem("USER", JSON.stringify(user));
      return res;
    },

    // 获取路由信息
    async getRouteInfo() {
      let md = model.GetQueryModel(
        "AccountMgrCtrl",
        "AdminGetRouteInfo",
        {}
      );
      let [err, res] = await to(postRequest(md));
      if (err) {
        return Promise.reject(err);
      }
      if (res.data.msg) {
        return Promise.reject(res.data.msg);
      }
      var routerJson = res.data.datas;
      localStorage.setItem("ROUTER_DATA", JSON.stringify(routerJson));
      return res;
    },

    // 登陆请求
    async handleLogin() {
      this.$refs.loginForm.validate(valid => {
        if (valid) {
          this.loading = true

          this.doLogin(this.datas).then((res) => {
            console.log("doLogin###", res)
            return this.getUserInfo();

          }).then((res) => {
            console.log("getUserInfo###", res)
            return this.getRouteInfo();

          }).then((res) => {
            console.log("getRouterInfo###", res, location.pathname)
            location.href = location.pathname;

          }).catch((err) => {
            console.error("handleLogin err", err);
            this.$message.warning(err);

          }).finally(() => {
            this.loading = false

          });
        }
      });
    },

    async doLogin() {
      let md = model.GetQueryModel(
        "AccountMgrCtrl",
        "AdminLogin",
        Object.assign({}, this.datas)
      );
      let [err, res] = await to(postRequest(md));
      if (err) {
        return Promise.reject(err);
      }
      if (res.data.msg) {
        return Promise.reject(res.data.msg);
      }
      localStorage.setItem("ACCESS_TOKEN", res.data.datas.access_token);
      return res;
    }
  },

  mounted() {
    // const { search, origin, pathname } = location;
    this.checkLogin({})
  },
};
</script>

<style lang="scss" scoped>
.login-container {
  /*box-shadow: 0 0px 8px 0 rgba(0, 0, 0, 0.06), 0 1px 0px 0 rgba(0, 0, 0, 0.02);*/
  -webkit-border-radius: 5px;
  border-radius: 5px;
  -moz-border-radius: 5px;
  background-clip: padding-box;
  margin: 180px auto;
  width: 350px;
  padding: 35px 35px 15px 35px;
  background: #fff;
  border: 1px solid #eaeaea;
  box-shadow: 0 0 25px #cac6c6;

  .title {
    margin: 0px auto 40px auto;
    text-align: center;
    color: #505458;
  }

  .remember {
    margin: 0px 0px 35px 0px;
  }
}
</style>