import { getUser } from "./api";
import { cityData } from "./cityData";

export default {
    name: "gconfig",
    accountType: {
        guest: 0, //客户
        admin: 1, //管理员
        staff: 2, //运营
    },
    isGuest() {
        let user = getUser();
        if (user == null) {
            return false;
        }
        return user.account_type == this.accountType.guest;
    },
    isAdmin() {
        let user = getUser();
        if (user == null) {
            return false;
        }
        return user.account_type == this.accountType.admin;
    },
    isStaff() {
        let user = getUser();
        if (user == null) {
            return false;
        }
        return user.account_type == this.accountType.staff;
    },

    getCityOptions() {
        let cityOptions = [];
        var tmpCitys = ['北京','天津','上海','重庆','香港特别行政区','澳门特别行政区'];
        for (var i = 0; i < cityData.length; i++) {
            if (tmpCitys.indexOf(cityData[i].name) == -1){
                for (var j = 0; j < cityData[i].sub.length; j++) {
                    var city = cityData[i].sub[j].name;
                    tmpCitys.push(city);

                    let shortCity = city;
                    if (city.indexOf("市") >= 0) {
                        shortCity = city.substring(0, city.length-1);
                    }
                    cityOptions.push( {label: shortCity, value: shortCity});
                }
            } else {
                cityOptions.push( {label: cityData[i].name, value: cityData[i].name});
            }
        }
        return cityOptions
    },

    terminals : [
        {label: "手机", value: "手机"},
        {label: "电脑", value: "电脑"},
    ],

    isBaidu(platformName) {
        return platformName.indexOf("百度") !== -1;
    },
    is360(platformName) {
        return platformName.indexOf("360") !== -1;
    },
    isUC(platformName) {
        return platformName.indexOf("UC") !== -1;
    },
    isVivo(platformName) {
        return platformName.indexOf("VIVO") !== -1;
    },
}