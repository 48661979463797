var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c(
        "el-col",
        {
          staticClass: "toolbar",
          staticStyle: {
            "padding-bottom": "0px",
            "padding-top": "5px",
            "padding-left": "5px",
          },
          attrs: { span: 24 },
        },
        [
          _c(
            "el-form",
            {
              attrs: {
                inline: true,
                model: _vm.filters,
                "label-width": "auto",
                size: "small",
                "label-position": "left",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "推广账号", prop: "advAccountName" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "140px" },
                      attrs: {
                        size: "small",
                        value: "",
                        filterable: "",
                        clearable: "",
                      },
                      model: {
                        value: _vm.filters.advAccountName,
                        callback: function ($$v) {
                          _vm.$set(_vm.filters, "advAccountName", $$v)
                        },
                        expression: "filters.advAccountName",
                      },
                    },
                    _vm._l(_vm.bindAdvAccountOptions, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { value: item.value, label: item.label },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              !_vm.gconfig.isGuest()
                ? _c(
                    "el-form-item",
                    { attrs: { label: "客户账号", prop: "guestAccountId" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "140px" },
                          attrs: {
                            size: "small",
                            value: "",
                            filterable: "",
                            clearable: "",
                          },
                          model: {
                            value: _vm.filters.guestAccountId,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.filters,
                                "guestAccountId",
                                _vm._n($$v)
                              )
                            },
                            expression: "filters.guestAccountId",
                          },
                        },
                        _vm._l(_vm.guestAccountOptions, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { value: item.value, label: item.label },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.gconfig.isAdmin()
                ? _c(
                    "el-form-item",
                    { attrs: { label: "运营账号", prop: "staffAccountId" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "140px" },
                          attrs: {
                            size: "small",
                            value: "",
                            filterable: "",
                            clearable: "",
                          },
                          model: {
                            value: _vm.filters.staffAccountId,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.filters,
                                "staffAccountId",
                                _vm._n($$v)
                              )
                            },
                            expression: "filters.staffAccountId",
                          },
                        },
                        _vm._l(_vm.staffAccountOptions, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { value: item.value, label: item.label },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "订单状态" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100px" },
                      attrs: {
                        size: "small",
                        value: "",
                        filterable: "",
                        clearable: "",
                      },
                      model: {
                        value: _vm.filters.orderFlag,
                        callback: function ($$v) {
                          _vm.$set(_vm.filters, "orderFlag", $$v)
                        },
                        expression: "filters.orderFlag",
                      },
                    },
                    _vm._l(_vm.orderOptions, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { value: item.value, label: item.label },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "电话/域名", prop: "domainOrPhone" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "140px" },
                    attrs: { size: "small", clearable: "" },
                    model: {
                      value: _vm.filters.domainOrPhone,
                      callback: function ($$v) {
                        _vm.$set(_vm.filters, "domainOrPhone", $$v)
                      },
                      expression: "filters.domainOrPhone",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "时间" } },
                [
                  _c("el-date-picker", {
                    staticStyle: { width: "250px" },
                    attrs: {
                      size: "small",
                      type: "daterange",
                      "value-format": "timestamp",
                      "range-separator": "至",
                      "start-placeholder": "开始日期",
                      "end-placeholder": "结束日期",
                    },
                    model: {
                      value: _vm.filters.timeRange,
                      callback: function ($$v) {
                        _vm.$set(_vm.filters, "timeRange", $$v)
                      },
                      expression: "filters.timeRange",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      staticStyle: { width: "50px" },
                      attrs: { type: "primary", size: "small" },
                      on: {
                        click: function ($event) {
                          return _vm.handleOrderList()
                        },
                      },
                    },
                    [_vm._v("查询")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              !_vm.gconfig.isStaff()
                ? _c(
                    "el-form-item",
                    [
                      _c("export-file", {
                        staticStyle: { width: "150px" },
                        on: { download: _vm.handleExport },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              !_vm.gconfig.isStaff()
                ? _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            size: "mini",
                            type: "primary",
                            disabled: _vm.selections.length == 0,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.showBatchModify("hide")
                            },
                          },
                        },
                        [_vm._v("批量隐藏")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      [
        _c(
          "el-table",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.dataLoading,
                expression: "dataLoading",
              },
            ],
            staticStyle: { width: "100%" },
            attrs: {
              data: _vm.dataList,
              stripe: "",
              "highlight-current-row": "",
              size: "small",
              height: "800",
            },
            on: { "selection-change": _vm.selectionChange },
          },
          [
            _c("el-table-column", {
              attrs: { type: "selection", width: "55" },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                prop: "title",
                label: "下单名字",
                width: "100",
                "show-overflow-tooltip": true,
              },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: { prop: "tel", label: "下单电话", width: "100" },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                prop: "xddz",
                label: "下单地址",
                width: "150",
                "show-overflow-tooltip": true,
              },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                prop: "szdq",
                label: "下单商品",
                width: "150",
                "show-overflow-tooltip": true,
              },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                prop: "content",
                label: "下单备注",
                width: "150",
                "show-overflow-tooltip": true,
              },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                prop: "time",
                label: "下单时间",
                width: "150",
                formatter: _vm.formatTime,
              },
            }),
            _vm._v(" "),
            _vm.gconfig.isAdmin()
              ? _c("el-table-column", {
                  attrs: {
                    prop: "visit_time",
                    label: "下单用时",
                    width: "100",
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: { prop: "adv_account", label: "推广账号", width: "150" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      scope.row.adv_account == ""
                        ? _c("span", [_vm._v("未绑定")])
                        : _c(
                            "span",
                            {
                              staticStyle: {
                                color: "green",
                                "font-weight": "bold",
                              },
                            },
                            [_vm._v(_vm._s(scope.row.adv_account) + " ")]
                          ),
                    ]
                  },
                },
              ]),
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: { prop: "account", label: "客户账号", width: "100" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      scope.row.account_guest == ""
                        ? _c("span", [_vm._v("未绑定")])
                        : _c(
                            "span",
                            {
                              staticStyle: {
                                color: "green",
                                "font-weight": "bold",
                              },
                            },
                            [
                              _vm._v(
                                " " + _vm._s(scope.row.account_guest) + " "
                              ),
                            ]
                          ),
                    ]
                  },
                },
              ]),
            }),
            _vm._v(" "),
            !_vm.gconfig.isGuest()
              ? _c("el-table-column", {
                  attrs: { prop: "account", label: "运营账号", width: "100" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.account_staff == ""
                              ? _c("span", [_vm._v("未绑定")])
                              : _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      color: "orange",
                                      "font-weight": "bold",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(scope.row.account_staff) +
                                        " "
                                    ),
                                  ]
                                ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    2093487155
                  ),
                })
              : _vm._e(),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: { prop: "wwwdb", label: "下单域名", width: "150" },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                prop: "ip",
                label: "来源ip",
                width: "120",
                "show-overflow-tooltip": true,
              },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                prop: "url",
                label: "来源页面",
                width: "150",
                "show-overflow-tooltip": true,
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c(
                        "a",
                        { attrs: { target: "_blank", href: scope.row.url } },
                        [_vm._v(_vm._s(scope.row.url))]
                      ),
                    ]
                  },
                },
              ]),
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: { prop: "id", label: "订单号", width: "100" },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: { prop: "quantity", label: "购买数量", width: "100" },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: { prop: "payment", label: "支付方式", width: "100" },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: { prop: "zcode", label: "邮编", width: "100" },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: { prop: "invalid", label: "无效订单", width: "100" },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: { label: "操作", "min-width": "270", fixed: "right" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _vm.gconfig.isAdmin()
                        ? _c(
                            "el-button",
                            {
                              staticStyle: {
                                width: "50px",
                                "margin-left": "3px",
                              },
                              attrs: { type: "danger", size: "small" },
                              on: {
                                click: function ($event) {
                                  return _vm.handleDelete(scope.row)
                                },
                              },
                            },
                            [_vm._v("删除")]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.gconfig.isStaff()
                        ? _c(
                            "el-button",
                            {
                              style:
                                "width: 60px; margin-left:3px;font-weight:bold;" +
                                (scope.row.deal == 1
                                  ? "color:green;"
                                  : "color:gray;"),
                              attrs: { size: "small" },
                              on: {
                                click: function ($event) {
                                  return _vm.handleDealOrder(scope.row)
                                },
                              },
                            },
                            [_vm._v("成交")]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.gconfig.isAdmin()
                        ? _c(
                            "el-button",
                            {
                              style:
                                "width: 50px; margin-left:3px;font-weight:bold;" +
                                (scope.row.hide == 0
                                  ? "color:green"
                                  : "color:gray"),
                              attrs: { size: "small" },
                              on: {
                                click: function ($event) {
                                  return _vm.handleHideOrder(scope.row)
                                },
                              },
                            },
                            [_vm._v("隐藏")]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.gconfig.isGuest()
                        ? _c(
                            "el-button",
                            {
                              style:
                                "width: 50px; margin-left:3px;font-weight:bold;" +
                                (scope.row.invalid == 0
                                  ? "color:green"
                                  : "color:gray"),
                              attrs: { size: "small" },
                              on: {
                                click: function ($event) {
                                  return _vm.handleInvlidOrder(scope.row)
                                },
                              },
                            },
                            [_vm._v("无效")]
                          )
                        : _vm._e(),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "el-col",
          { staticClass: "toolbar", attrs: { span: 24 } },
          [
            _c("el-pagination", {
              staticStyle: { float: "right" },
              attrs: {
                layout: "total, sizes, prev, pager, next",
                "current-page": this.page,
                total: _vm.total,
                "page-size": _vm.pageSize,
                "page-sizes": [100, 200, 400],
              },
              on: {
                "current-change": _vm.handleCurrentChange,
                "size-change": _vm.handleSizeChange,
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "el-dialog",
          {
            attrs: {
              title: _vm.batchModifyTitle,
              visible: _vm.batchModifyVisible,
              "close-on-click-modal": false,
              width: "30%",
            },
            on: {
              "update:visible": function ($event) {
                _vm.batchModifyVisible = $event
              },
            },
          },
          [
            _c(
              "el-form",
              {
                ref: "batchform",
                attrs: {
                  model: _vm.batchForm,
                  "label-width": "100px",
                  "label-position": "left",
                },
              },
              [
                _vm.batchForm.modType == "hide"
                  ? _c(
                      "el-col",
                      { attrs: { span: 24 } },
                      [
                        _c(
                          "el-select",
                          {
                            key: "1",
                            staticStyle: { width: "70%" },
                            attrs: { size: "small", value: "" },
                            model: {
                              value: _vm.batchForm.modValue,
                              callback: function ($$v) {
                                _vm.$set(_vm.batchForm, "modValue", $$v)
                              },
                              expression: "batchForm.modValue",
                            },
                          },
                          [
                            _c("el-option", {
                              key: 0,
                              attrs: { value: 0, label: "显示" },
                            }),
                            _vm._v(" "),
                            _c("el-option", {
                              key: 1,
                              attrs: { value: 1, label: "隐藏" },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "dialog-footer",
                attrs: { slot: "footer" },
                slot: "footer",
              },
              [
                _c(
                  "el-button",
                  {
                    nativeOn: {
                      click: function ($event) {
                        return _vm.formCancel("batchform")
                      },
                    },
                  },
                  [_vm._v("取消")]
                ),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary", loading: _vm.formLoading },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.handleBatchModify($event)
                      },
                    },
                  },
                  [_vm._v("提交")]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }