import { postRequest } from "../../api/api";
import model from "./model";
import to from "await-to-js";

var DEFAULT_PATTERN = 'yyyy-MM-dd';
var SIGN_REGEXP = /([yMdhsm])(\1*)/g;

function padding(s, len) {
    var len = len - (s + '').length;
    for (var i = 0; i < len; i++) {
        s = '0' + s;
    }
    return s;
};

export default {
    Domain: 'https://res.kaiheicloud.com',
    PublishDomain: 'https://publish.kaiheicloud.com',
    DomainRes: 'https://res.kaiheicloud.com',
    Http: 'http',
    DomainCaijiyouxi: 'kaiheicloud.com',
    ClientDownload: "https://api.kaiheicloud.com/yungame/download_client",
    SteamDomain : 'https://media.st.dl.pinyuncloud.com',
    SteamCDNDomain : 'https://cdn.cloudflare.steamstatic.com',

    //如果是阿里云路径， 就拼接上 域名
    //如果是 www caijiyouxi 域名， 就换成 res 域名
    changeUrl(url1) {
        let DomainRes = 'https://res.kaiheicloud.com'
        if (url1.indexOf('http') === -1) {
            return DomainRes + url1
        }

        let u = new URL(url1)
        if (u === undefined) {
            return url1
        }

        if (u.host === this.Domain) {
            u.host = this.DomainRes
            return u.href
        }
        return url1
    },


    showResMsg: function (res, msg) {
        if (!this.isEmpty(res.data.msg)) {
            this.$message({
                message: res.data.msg,
                type: 'warning'
            });
        } else {
            this.$message({
                message: msg,
                type: 'success'
            });
        }
    },

    isEmpty: function (obj) {
        if (typeof obj == "undefined" || obj == null || obj == "") {
            return true;
        } else {
            return false;
        }
    },

    formatTen: function (num) {
        return num > 9 ? (num + "") : ("0" + num);
    },
    formatPercent: function (num) {
        if (num > 0) {
            return String(num) + "%";
        } else {
            return "";
        }
    },

    praseDate: function (chinaDate) {
        if (this.isEmpty(chinaDate)) {
            return "";
        }
        var d = new Date(chinaDate);
        var datetime = d.getFullYear() + '-' + this.formatTen(d.getMonth() + 1) + '-' + this.formatTen(d.getDate());
        return datetime;
    },

    praseMonth: function (chinaDate) {
        if (this.isEmpty(chinaDate)) {
            return "";
        }
        var d = new Date(chinaDate);
        var datetime = d.getFullYear() + this.formatTen(d.getMonth() + 1);
        return datetime;
    },

    Unix2Date: function (unixtime) {
        var unixTimestamp = new Date(unixtime * 1000);
        var Y = unixTimestamp.getFullYear();
        var M = ((unixTimestamp.getMonth() + 1) > 10 ? (unixTimestamp.getMonth() + 1) : '0' + (unixTimestamp.getMonth() + 1));
        var D = (unixTimestamp.getDate() > 10 ? unixTimestamp.getDate() : '0' + unixTimestamp.getDate());
        var toDay = Y + '-' + M + '-' + D;
        return toDay;
    },

    boolFormatter(row, column) {
        const is_multiple = row[column.property];
        if(is_multiple) {
            return "是"
        } else {
            return "否"
        }
    },


    Unix2DateTime: function (inputTime) {
        var date = new Date(inputTime);
        var y = date.getFullYear();
        var m = date.getMonth() + 1;
        m = m < 10 ? ('0' + m) : m;
        var d = date.getDate();
        d = d < 10 ? ('0' + d) : d;
        var h = date.getHours();
        h = h < 10 ? ('0' + h) : h;
        var minute = date.getMinutes();
        var second = date.getSeconds();
        minute = minute < 10 ? ('0' + minute) : minute;
        second = second < 10 ? ('0' + second) : second;
        return y + '-' + m + '-' + d + ' ' + h + ':' + minute + ':' + second;
    },

    getQueryStringByName: function (name) {
        var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
        var r = window.location.search.substr(1).match(reg);
        var context = "";
        if (r != null)
            context = r[2];
        reg = null;
        r = null;
        return context == null || context == "" || context == "undefined" ? "" : context;
    },
    //用于字段ID名称转换
    FormatId2Name: function (data, value, id, name) {

        if (data instanceof Array) {
            if (this.isEmpty(id)) {
                id = "id";
            }
            if (this.isEmpty(name)) {
                name = "name";
            }
            if (data.length == 0) {
                return "";
            }
            var length = data.length;
            for (var i = 0; i < length; i++) {
                var item = data[i];
                if (item[id] == value) {
                    return item[name];
                }
            }
        } else {
            return "";
        }
    },

    //将服务端返回端数据转换为可以进行ID名称转身端数据
    FormatId2NameDatas: function (data, id, name) {
        var datas = [];
        if (data instanceof Array) {
            if (this.isEmpty(id)) {
                id = "Id";
            }
            if (this.isEmpty(name)) {
                name = "Name";
            }
            if (data.length == 0) {
                return datas;
            }
            var length = data.length;
            for (var i = 0; i < length; i++) {
                var item = data[i];
                var temp = {
                    id: item[id],
                    name: item[name]
                };
                datas.push(temp);
            }

            return datas;
        } else {
            return datas
        }
    },
    //stirng转Int数组(element select 在设置默认值时，如果key为整形3，那么必须将string的3转化为整形的3，否则select无法进行id名称转化)
    str2IntArr: function (dataStr) {
        if (this.isEmpty(dataStr)) {
            return [];
        }
        var dataStrArr = dataStr.split(",");//分割成字符串数组
        var dataIntArr = [];//保存转换后的整型字符串

        dataIntArr = dataStrArr.map(function (data) {
            return +data;
        });
        return dataIntArr;
    },


    formatDate: {
        format: function (date, pattern) {
            pattern = pattern || DEFAULT_PATTERN;
            return pattern.replace(SIGN_REGEXP, function ($0) {
                switch ($0.charAt(0)) {
                    case 'y':
                        return padding(date.getFullYear(), $0.length);
                    case 'M':
                        return padding(date.getMonth() + 1, $0.length);
                    case 'd':
                        return padding(date.getDate(), $0.length);
                    case 'w':
                        return date.getDay() + 1;
                    case 'h':
                        return padding(date.getHours(), $0.length);
                    case 'm':
                        return padding(date.getMinutes(), $0.length);
                    case 's':
                        return padding(date.getSeconds(), $0.length);
                }
            });
        },
        parse: function (dateString, pattern) {
            var matchs1 = pattern.match(SIGN_REGEXP);
            var matchs2 = dateString.match(/(\d)+/g);
            if (matchs1.length == matchs2.length) {
                var _date = new Date(1970, 0, 1);
                for (var i = 0; i < matchs1.length; i++) {
                    var _int = parseInt(matchs2[i]);
                    var sign = matchs1[i];
                    switch (sign.charAt(0)) {
                        case 'y':
                            _date.setFullYear(_int);
                            break;
                        case 'M':
                            _date.setMonth(_int - 1);
                            break;
                        case 'd':
                            _date.setDate(_int);
                            break;
                        case 'h':
                            _date.setHours(_int);
                            break;
                        case 'm':
                            _date.setMinutes(_int);
                            break;
                        case 's':
                            _date.setSeconds(_int);
                            break;
                    }
                }
                return _date;
            }
            return null;
        }

    },

    /*
    *
    * */
    changeTime(timeString) {

        if (timeString == null) {
            return ""
        }

        return this.formatDate.format(new Date(timeString), 'yyyy-MM-dd hh:mm:ss')
    },

    formatTimeStr(timeString, pattern) {
        return this.formatDate.format(new Date(timeString), pattern)
    },


    howLongNoHeartBeat(now, last) {

        let second = parseInt(
            (new Date(now).getTime()
                - new Date(last).getTime()) / 1000)

        if (second > 400000 * 60 * 60) {
            return '-'
        }

        let minute = parseInt(second / 60 % 60)
        let hour = parseInt(second / 3600)
        second = second % 60

        if (hour > 0) {
            return hour + '小时' + minute + '分钟' + second + '秒'
        }

        if (minute > 0) {
            return minute + '分钟' + second + '秒'
        }

        return second + '秒'
    },

    howLong(lastHeartBeat) {
        let second = parseInt(lastHeartBeat / 1000)

        let minute = parseInt(second / 60 % 60)
        let hour = parseInt(second / 3600)
        second = second % 60

        if (hour > 0) {
            return hour + '小时' + minute + '分钟' + second + '秒'
        }

        if (minute > 0) {
            return minute + '分钟' + second + '秒'
        }

        return second + '秒'
    },

    howLongV2(intervalSecond) {
        let minute = parseInt(intervalSecond / 60 % 60)
        let hour = parseInt(intervalSecond / 3600)
        let second = parseInt(intervalSecond % 60)

        if (hour > 0) {
            return hour + '小时' + minute + '分钟' + second + '秒'
        }

        if (minute > 0) {
            return minute + '分钟' + second + '秒'
        }

        return second + '秒'
    },

    //例子：20200514001936 --> 2020-05-14 00:19:36
    changeTimeV2(timeString) {
        let year = timeString.substr(0, 4)
        let month = timeString.substr(4, 2)
        let day = timeString.substr(6, 2)
        let hour = timeString.substr(8, 2)
        let minute = timeString.substr(10, 2)
        let second = timeString.substr(12, 2)

        return year + '-' + month + '-' + day + ' ' + hour + ':' + minute + ':' + second
    },

    //
    transferbps(bps) {
        bps = parseInt(bps)

        if (bps >= 1000) {
            bps = parseInt(bps / 1000)
            if (bps >= 1000) {
                bps = parseInt(bps / 1000)
                if (bps >= 1000) {
                    bps = parseInt(bps / 1000)

                    return bps + ' Gbps'
                }

                return bps + ' Mbps'
            }

            return bps + ' Kbps'
        }

        return bps + ' bps'

    },


    getPlatformOptions: async (parent) => {
        let platformOptions = [];

        let params = { page: 1, pageSize: 100, name: "" };
        let queryModel = model.GetQueryModel("PlatformCtrl", "GetList", params);
        let [err, res] = await to(postRequest(queryModel));
        if (err) {
            parent.$message.warning(err);

        } else if (res.data.msg !== "") {
            parent.$message.warning(res.data.msg);

        } else {
            for (let val of res.data.datas) {
                platformOptions.push({ label: val.name, value: val.id });
            }
        }
        console.log("getPlatformOptions", platformOptions);
        return platformOptions;
    },

};
