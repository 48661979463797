<template>
    <section>
         <!-- 选择栏 -->
         <el-col :span="24" class="toolbar" style="padding-bottom: 0; padding-left: 5px;" >
            <el-form :inline="true" :model="filters" ref="filtersform">
                <el-row>
                    <el-form-item label="平台" prop="platformId">
                        <el-select size="small" v-model="filters.platformId" value="" style="width:140px" filterable clearable>
                            <el-option v-for="item in platformOptions" :key="item.value" :value="item.value"
                                :label="item.label"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="域名" prop="domainName">
                        <el-select size="small" v-model="filters.domainName" value="" style="width:140px" filterable clearable>
                            <el-option v-for="item in bindDomainOptions" :key="item.value" :value="item.value"
                                :label="item.label"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="时间" required prop="timeRange">
                        <el-date-picker size="small" v-model="filters.timeRange" type="daterange" value-format="timestamp"
                            range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" style="width:250px">
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" size="small" v-on:click="handleList()">查询</el-button>
                        <el-button size="small" v-on:click="handleAdd()" v-if="!gconfig.isGuest()">录入</el-button>
                        <!-- <el-button size="small" v-on:click="handleAdd(true)" v-if="!gconfig.isGuest()">批量录入</el-button> -->
                    </el-form-item>
                </el-row>
            </el-form>
        </el-col>

        <!-- 列表 -->
        <template>
            <el-table :data="dataList" stripe highlight-current-row v-loading="dataLoading" size="small" height="800"
                style="width: 100%;">
                <el-table-column prop="dt" label="日期" width="100"></el-table-column>>
                <el-table-column prop="platform" label="平台" width="100" :formatter="formatPlatform"></el-table-column>>
                <el-table-column prop="uc_name" label="域名" width="150"></el-table-column>
                <el-table-column prop="info_cost" label="消费金额" width="150"></el-table-column>
                <el-table-column prop="create_time" label="创建时间" width="150" :formatter="formatTime"></el-table-column>

                <el-table-column label="操作" min-width="240" fixed="right">
                    <template slot-scope="scope">
                        <el-button v-if="!gconfig.isGuest()" type="danger" size="small" @click="handleDelete(scope.row)" style="width: 50px; margin-left: 3px;">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <!-- 翻页 -->
            <el-col :span="24" class="toolbar">
                <el-pagination layout="total, sizes, prev, pager, next" :current-page="this.page"
                    :total="total"
                    :page-size="pageSize" 
                    @current-change="handleCurrentChange" 
                    @size-change="handleSizeChange"
                    :page-sizes="[100,200,400]"
                    style="float:right;">
                </el-pagination>
            </el-col> 
        </template>

        <!-- 添加 -->
        <el-dialog title="添加" :visible.sync="formVisible" :close-on-click-modal="false" width="60%">
            <el-form :model="formData" label-width="100px" ref="addform" label-position="left">
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="日期" prop="timestamp" required label-width="30%">
                            <el-date-picker size="small" v-model="formData.timestamp" type="date" value-format="timestamp"
                             placeholder="日期" style="width:250px">
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="域名" prop="domain" required label-width="30%">
                            <el-select size="small" v-model="formData.domain" value="" style="width:250px" filterable>
                                <el-option v-for="item in bindDomainOptions" :key="item.value" :value="item.value"
                                    :label="item.label2"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="消耗金额" prop="adv_cost" required label-width="30%">
                            <el-input style="width:250px" size="small" v-model.number="formData.adv_cost"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click.native="handleAddCancel">取消</el-button>
                <el-button type="primary" @click.native="handleAddSubmit" :loading="formLoading">提交</el-button>
            </div>
        </el-dialog>
    </section>
</template>

<script>
import util from "../../common/js/util";
import gconfig from "../../api/gconfig";
import model from "../../common/js/model";
import { postRequest, formatTimestamp, getDotTime} from "../../api/api";

export default {
    data() {
        return {
            filters: {
                platformId: "", 
                domainName: "",
                timeRange: [getDotTime() * 1000, getDotTime() * 1000],
            },
            page: 1,
            pageSize: 100,
            total: 1,
            dataList: [],
            dataLoading: false,
            formData: {
                timestamp: getDotTime() * 1000,
                domain: "",
                adv_cost: "",
            },
            formVisible: false,
            formLoading: false,

            gconfig: gconfig,
            platformOptions: [],
            bindDomainOptions: [],
        }
    },

    methods: {
        formatTime(row, column, cellValue) {
            if (cellValue == 0) {
                return "0000-00-00 00:00:00"
            }
            return formatTimestamp(cellValue);
        },

        formatPlatform(row, column, cellValue) {
            for (let item of this.platformOptions) {
                if (item.value == cellValue) {
                    return item.label;
                }
            }
            if (cellValue == 0) {
                return "未设置:"+cellValue
            }
            return "未知:"+cellValue
        },

        handleList() {
            this.$refs.filtersform.validate(valid => {
                if (valid) {
                    let params = {
                        page: this.page,
                        pageSize: this.pageSize,
                        platformId: this.filters.platformId,
                        domainName: this.filters.domainName,
                        timeRange: this.filters.timeRange,
                    }
                    this.dataLoading = true;
                    let queryModel = model.GetQueryModel("AdvAccountCtrl", "GetTurnoverList", params);
                    postRequest(queryModel).then(res => {
                        this.dataLoading = false;

                        if (res.data.msg !== "") {
                            this.$message.warning(res.data.msg);
                            return;
                        }
                        if (this.page === 1) {
                            this.total = res.data.total;
                        }
                        this.dataList = res.data.datas;
                        console.log("GetList", this.dataList);

                        });
                    }
                });
            },

            handleCurrentChange(page) {
                this.page = page;
                this.handleList();
            },

            handleSizeChange(val) {
                this.pageSize = val;
                this.handleList();
            },

            handleBindDomainOptions() {
                let params = {}
                this.dataLoading = true;
                let queryModel = model.GetQueryModel("DomainMgrCtrl", "GetBindDomainOptions", params);
                postRequest(queryModel).then(res => {
                    this.dataLoading = false;

                    if (res.data.msg !== "") {
                        this.$message.warning(res.data.msg);
                    return;
                }
                //过滤调百度平台
                for (let item of res.data.datas) {
                    let platformName = "";
                    for (let pla of this.platformOptions) {
                        if (pla.value == item.platform) {
                            platformName = pla.label;
                            break
                        }
                    }
                    if (platformName != "") {
                        item.label2 = item.value + " --" + platformName;
                        this.bindDomainOptions.push(item);
                    }
                }
                console.log("handleBindDomainOptions", this.bindDomainOptions);
            });
        },

        handleAdd(isBatch) {
            if (isBatch) {
                this.$message.warning("暂未实现");
                return;
            }
            this.formVisible = true;
            this.formLoading = false; 
        },

        getDomainPlatform(domain) {
            for (let item of this.bindDomainOptions) {
                if (item.value == domain) {
                    return item.platform;
                }
            }
            return 0;
        },

        handleAddSubmit() {
            this.$refs.addform.validate(valid => {
                if (valid) {
                    this.formLoading = true;
                    let params = Object.assign({}, this.formData);
                    let platform = this.getDomainPlatform(params.domain);
                    if (platform == 0) {
                        this.$message.warning("域名平台错误:"+params.domain);
                        return;
                    }
                    params.platform = platform;

                    let queryModel = model.GetQueryModel("AdvAccountCtrl", "AddTurnover", params);
                    postRequest(queryModel).then(res => {
                        this.formLoading = false;
                        if (res.data.msg !== "") {
                            this.$message.warning(res.data.msg);
                            return;
                        }
                        this.$message.success("录入成功");
                    });
                }
            });
        },
        handleAddCancel() {
            this.formVisible = false;
            this.formLoading = false;
            this.$refs.addform.resetFields();
        },
        handleDelete(row) {
            this.$confirm("确认删除吗？", "提示", {}).then(() => {
                this.dataLoading = true;
                let params = Object.assign({}, row);
                let queryModel = model.GetQueryModel("AdvAccountCtrl", "DelTurnover", params);
                postRequest(queryModel).then(res => {
                    this.dataLoading = false;
                    if (res.data.msg !== "") {
                        this.$message.warning(res.data.msg);
                        return;
                    }
                    this.$message.success("删除成功");
                    this.handleList();
                });
            });
        },
    },
    mounted() {
        this.dataLoading = true
        util.getPlatformOptions(this).then(res => {
            this.dataLoading = false;
            //过滤掉百度平台
            this.platformOptions = [];
            for (let item of res) {
                if (item.label.indexOf("百度") == -1) {
                    this.platformOptions.push(item);
                }
            }
            this.handleBindDomainOptions()
            this.handleList();
        });
    }
}
</script>
<style scoped>
</style>